import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { memo } from "react";
import { css } from "../../../styled-system/css";
import Header from "../../components/header";

export const Route = createFileRoute("/_authed/about-campaign")({
  loader: async ({ context: { tenant } }) => {
    return {
      tenant,
    };
  },
  component: AboutCampaign,
});

const Heading = memo(({ children }: { children: React.ReactNode }) => (
  <h2
    className={css({
      mt: "16px",
      mb: "8px",
      fontSize: "24px",
      fontWeight: "400",
      color: "text.primary",
    })}
  >
    {children}
  </h2>
));

const Miyagi = () => {
  return (
    <>
      <Heading>ポイント抽選とは</Heading>
      <p>
        「みやぎ防災ミニアプリ」の利用登録をすると、アプリ内で1回限り抽選に参加できます。
        当選者には3000ポイントが即時付与されます。
        初回抽選で落選した場合でも、令和7年1月31日の再抽選で当選するチャンスがあります。
        獲得したポイントは、令和7年1月6日から令和7年2月28日まで対象店舗で1ポイント＝1円として利用できます。
      </p>
      <Heading>参加の方法</Heading>
      <div
        className={css({
          display: "flex",
          flexDirection: "column",
          gap: "8px",
        })}
      >
        <p>
          抽選への参加はとても簡単です。以下の2ステップで手続きを完了できます。
        </p>
        <h3
          className={css({
            fontWeight: "bold",
          })}
        >
          STEP1：みやぎ防災ミニアプリに登録
        </h3>
        <p>
          「みやぎ防災ミニアプリ」の利用登録をすることで、即時抽選への参加資格を得られます。
        </p>
        <h3
          className={css({
            fontWeight: "bold",
          })}
        >
          STEP2：抽選への参加
        </h3>
        <p>
          登録が完了したら、アプリ内の「ポイント抽選」ページで抽選に参加できます。
        </p>

        <Heading>スケジュール</Heading>
        <p
          className={css({
            fontSize: "14px",
            color: "text.secondary",
          })}
        >
          ※以下のスケジュールは予定であり、変更となる可能性があります。ポイント配布予定の上限に達し次第、即時抽選は終了します。
        </p>
        <div
          className={css({
            mt: "8px",
          })}
        >
          <p>令和6年11月18日：即時抽選キャンペーン開始</p>
          <p>令和7年1月6日：ポイントの利用開始</p>
          <p>令和7年1月25日：即時抽選キャンペーン終了</p>
          <p>令和7年1月31日：一斉再抽選の実施</p>
        </div>
      </div>
    </>
  );
};

const Default = () => {
  return (
    <>
      <Heading>ポイント抽選とは</Heading>
      <p>
        アプリ内で1回限り抽選に参加できます。
        当選者には2,000ポイントが即時付与されます。
        初回抽選で落選した場合でも、令和7年1月31日の再抽選で当選するチャンスがあります。
        獲得したポイントは、令和7年1月6日から令和7年2月28日まで対象店舗で1ポイント＝1円として利用できます。
      </p>
      <Heading>参加の方法</Heading>
      <div
        className={css({
          display: "flex",
          flexDirection: "column",
          gap: "8px",
        })}
      >
        <p>
          抽選への参加はとても簡単です。ホーム画面の「抽選に参加」ボタンをタップして、ポイントを獲得しましょう！
        </p>

        <Heading>スケジュール</Heading>
        <p
          className={css({
            fontSize: "14px",
            color: "text.secondary",
          })}
        >
          ※以下のスケジュールは予定であり、変更となる可能性があります。ポイント配布予定の上限に達し次第、即時抽選は終了します。
        </p>
        <div
          className={css({
            mt: "8px",
          })}
        >
          <p>令和6年11月18日：即時抽選キャンペーン開始</p>
          <p>令和7年1月6日：ポイントの利用開始</p>
          <p>令和7年1月25日：即時抽選キャンペーン終了</p>
          <p>令和7年1月31日：一斉再抽選の実施</p>
        </div>
      </div>
    </>
  );
};

function AboutCampaign() {
  const navigate = useNavigate();
  const { tenant } = Route.useLoaderData();

  return (
    <>
      <Header
        title="キャンペーン詳細"
        onClickBack={() => {
          navigate({ to: "/" });
        }}
      />

      <div
        className={css({
          p: "16px",
          pb: "64px",
        })}
      >
        {tenant.slug === "miyagi" ? <Miyagi /> : <Default />}
      </div>
    </>
  );
}
