import { readResourceRaw } from "@pocketsign/in-app-sdk";
import { createFileRoute, redirect } from "@tanstack/react-router";
import { MIYAGI_BOUSAI_RESOURCE_ID } from "../../../config";
import { login } from "../../../libs/connect";
import { AppError, ERROR_CODES } from "../../../libs/errors";
import { sdk } from "../../../libs/sdk";

export const Route = createFileRoute("/_authed/lottery/bousai/callback")({
  loader: async ({ context: { tenant } }) => {
    const response = await readResourceRaw(sdk, {
      resourceId: MIYAGI_BOUSAI_RESOURCE_ID,
    });

    // 防災の登録が確認できない場合はエラー
    // 防災の登録が完了せずここに到達することはない
    if (response.result === "error") {
      throw new AppError(ERROR_CODES.BOUSAI_RESOURCE_READ_FAILED, undefined, {
        sdkErrorNo: response.errno,
      });
    }

    if (response.value === null || JSON.parse(response.value).length === 0) {
      throw new AppError(ERROR_CODES.BOUSAI_RESOURCE_READ_FAILED);
    }

    // 防災IDをバックエンドに登録するためログインし直す
    await login(tenant);

    return redirect({
      to: "/lottery/processing",
    });
  },
});
