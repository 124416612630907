import {
  Outlet,
  createRootRoute,
  useRouterState,
} from "@tanstack/react-router";
import React, { Suspense } from "react";
import { Toaster } from "react-hot-toast";
import "../index.css";
import { ErrorBoundary } from "../components/error-boundary";
import { PageLoading } from "../components/page-loading";
import { FooterProvider } from "../contexts/footer-context";

const enableDevtools = false;

const TanStackRouterDevtools = !enableDevtools
  ? () => null // Render nothing in production
  : React.lazy(() =>
      // Lazy load in development
      import("@tanstack/router-devtools").then((res) => ({
        default: res.TanStackRouterDevtools,
        // For Embedded Mode
        // default: res.TanStackRouterDevtoolsPanel
      })),
    );

export const Route = createRootRoute({
  component: RootComponent,
});

function RootComponent() {
  const state = useRouterState();
  const authedRoute = state.matches.find((m) => m.id === "/_authed/");

  // 初期読み込み時の認証中(_authed の beforeLoad 中)にローディング画面を表示する
  if (
    authedRoute &&
    authedRoute.status === "pending" &&
    authedRoute.cause === "enter"
  ) {
    return <PageLoading />;
  }

  return (
    <>
      <ErrorBoundary>
        <Toaster />
        <FooterProvider>
          <Outlet />
        </FooterProvider>
        <Suspense>
          <TanStackRouterDevtools />
        </Suspense>
      </ErrorBoundary>
    </>
  );
}
