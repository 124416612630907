import { Link, createFileRoute, useNavigate } from "@tanstack/react-router";
import { memo } from "react";
import Info from "~icons/material-symbols/info-outline";
import Person from "~icons/material-symbols/person-outline";
import Warning from "~icons/material-symbols/warning-outline";
import { css } from "../../../../styled-system/css";
import CheckCircle from "../../../assets/check_circle.svg";
import UserCircle from "../../../assets/user_circle.svg";
import { Card } from "../../../components/card";
import Header from "../../../components/header";
import { AppError, ERROR_CODES } from "../../../libs/errors";

type CampaignStatus = "active" | "ended" | "fulfilled";

export const Route = createFileRoute("/_authed/invitation/detail")({
  loader: async ({ context: { client } }) => {
    const invitation = await client.getMyInvitation({}).catch((error) => {
      throw new AppError(
        ERROR_CODES.CONNECT_GET_MY_INVITATION_FAILED,
        error.message,
        {
          originalError: error,
        },
      );
    });

    let campaignStatus: CampaignStatus = "active";

    if (invitation.isCampaignOver) {
      campaignStatus = "ended";
    }

    if (invitation.invitees.length >= 10) {
      campaignStatus = "fulfilled";
    }

    const { invitees } = invitation;
    return { invitees, campaignStatus };
  },
  component: InvitationDetail,
});

const ClearBox = () => (
  <div
    className={css({
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: "6px",
      fontSize: "12px",
      color: "text.accentSuccess",
    })}
  >
    <img src={CheckCircle} alt="達成" />
    <div>達成</div>
  </div>
);

const NotClearBox = () => (
  <div
    className={css({
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: "6px",
      fontSize: "12px",
      color: "text.accentSuccess",
    })}
  >
    <img src={UserCircle} alt="未達成" />
  </div>
);

const CampaignWarningMessage = memo(
  ({ campaignStatus }: { campaignStatus: CampaignStatus }) => {
    if (campaignStatus === "active") {
      return null;
    }

    const message = {
      title: "",
      description: "",
    };

    if (campaignStatus === "ended") {
      message.title = "キャンペーンは終了しました";
      message.description =
        "500ptプレゼントキャンペーンは先着6万名様までに達したため終了しました。";
    }

    if (campaignStatus === "fulfilled") {
      message.title = "プレゼント上限に達しました";
      message.description =
        "500ptプレゼント上限の2回に達したため、キャンペーン対象外となりました。";
    }

    return (
      <div
        className={css({
          bg: "surface.accentWarnLight",
          p: "16px",
          display: "flex",
          flexDirection: "column",
          gap: "8px",
        })}
      >
        <div
          className={css({
            display: "flex",
            alignItems: "center",
            gap: "8px",
            color: "text.primary",
          })}
        >
          <Warning
            className={css({
              color: "text.accentWarn",
            })}
          />
          <div>{message.title}</div>
        </div>
        <div
          className={css({
            color: "text.secondary",
            fontSize: "14px",
          })}
        >
          {message.description}
        </div>
      </div>
    );
  },
);

function InvitationDetail() {
  const { invitees, campaignStatus } = Route.useLoaderData();
  const navigate = useNavigate();

  const clearCount = invitees.length >= 10 ? 5 : invitees.length % 5;

  return (
    <>
      <Header
        title="友だち紹介キャンペーン"
        onClickBack={() => {
          navigate({ to: "/invitation" });
        }}
      />

      <CampaignWarningMessage campaignStatus={campaignStatus} />

      <div
        className={css({
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          px: "16px",
          py: "24px",
        })}
      >
        <h1
          className={css({
            fontSize: "24px",
            fontWeight: "400",
            color: "text.primary",
            textAlign: "center",
          })}
        >
          お友だちを5名紹介すると
          <br />
          500ptプレゼント
        </h1>

        <p
          className={css({
            fontSize: "16px",
            color: "text.secondary",
            textAlign: "center",
          })}
        >
          先着6万名様
          <br />
          500pt付与は1人2回まで
        </p>

        <Card>
          <div
            className={css({
              display: "flex",
              flexDirection: "column",
              gap: "20px",
            })}
          >
            <div
              className={css({
                fontSize: "16px",
                color: "text.primary",
                fontWeight: "400",
              })}
            >
              あなたの紹介ステータス
            </div>
            <div
              className={css({
                display: "flex",
                justifyContent: "space-between",
                px: "16px",
              })}
            >
              {Array.from({ length: clearCount }).map((_, index) => (
                <ClearBox key={`clear-box-${index}-${clearCount}`} />
              ))}
              {Array.from({ length: 5 - clearCount }).map((_, index) => (
                <NotClearBox key={`not-clear-box-${index}-${clearCount}`} />
              ))}
            </div>

            <div
              className={css({
                display: "flex",
                justifyContent: "center",
              })}
            >
              <Link
                to={"/invitation"}
                className={css({
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "8px",
                  bg: "surface.accentPrimary",
                  color: "white",
                  fontSize: "16px",
                  rounded: "xl",
                  px: "16px",
                  py: "8px",
                })}
              >
                <Person />
                <span>友だち紹介</span>
              </Link>
            </div>
          </div>
        </Card>

        <Card>
          <div
            className={css({
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            })}
          >
            <div
              className={css({
                display: "flex",
                alignItems: "center",
                color: "border.accentPrimary",
                gap: "6px",
              })}
            >
              <Info />
              <h3>お友だち紹介のやり方</h3>
            </div>

            <div
              className={css({
                textAlign: "left",
                display: "flex",
                flexDirection: "column",
                gap: "16px",
              })}
            >
              <h4
                className={css({
                  fontSize: "16px",
                  color: "text.primary",
                  fontWeight: "700",
                })}
              >
                お友だちを紹介する方法
              </h4>
              <ol
                className={css({
                  listStyle: "decimal",
                  pl: "16px",
                })}
              >
                <li>ホーム画面の「友だち紹介」ボタンをタップします。</li>
                <li>
                  表示される紹介コードやQRコードを、お友だちに共有してください。QRコードを送信するか、直接紹介コードを伝えることができます。
                </li>
                <li>
                  お友だちがアプリ内で紹介コードを入力またはQRコードを読み取ってアカウントを作成すると、紹介が完了します。
                </li>
                <li>
                  5名のお友だちを紹介すると、500ポイントがプレゼントされます。
                </li>
              </ol>

              <h4
                className={css({
                  fontSize: "16px",
                  color: "text.primary",
                  fontWeight: "700",
                  mt: "16px",
                })}
              >
                お友だちに紹介される方法
              </h4>
              <ol
                className={css({
                  listStyle: "decimal",
                  pl: "16px",
                })}
              >
                <li>
                  紹介された場合、ホーム画面の「招待コードを入力」欄に紹介コードを入力するか、QRコードを読み取ってください。
                </li>
                <li>
                  紹介の有効期限は、ミニアプリに登録してから<b>1週間以内</b>
                  です。それまでに紹介が有効化されない場合、ポイントの対象外となりますのでご注意ください。
                </li>
                <li>
                  紹介が適用されたか確認するためには、紹介者側の「紹介ステータス」をご確認ください。
                </li>
              </ol>
            </div>
          </div>
        </Card>
      </div>
    </>
  );
}
