import Warning from "~icons/material-symbols/warning-outline";
import { css } from "../../styled-system/css";

type WarningBannerProps = {
  title: string;
  description: React.ReactNode;
};

export default function WarningBanner({
  title,
  description,
}: WarningBannerProps) {
  return (
    <div
      className={css({
        bg: "surface.accentWarnLight",
        p: "16px",
        display: "flex",
        flexDirection: "column",
        gap: "8px",
      })}
    >
      <div
        className={css({
          display: "flex",
          alignItems: "center",
          gap: "8px",
          color: "text.primary",
        })}
      >
        <Warning
          className={css({
            color: "text.accentWarn",
          })}
        />
        <div>{title}</div>
      </div>
      <div
        className={css({
          color: "text.secondary",
          fontSize: "14px",
        })}
      >
        {description}
      </div>
    </div>
  );
}
