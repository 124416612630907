import { _internal_2 } from "@pocketsign/in-app-sdk";
import { createFileRoute } from "@tanstack/react-router";
import { useCallback, useEffect, useState } from "react";
import type { Tenant } from "schema/gen/es/chiikipoint/model/v2/model_pb";
import { z } from "zod";
import { css } from "../../../styled-system/css";
import Header from "../../components/header";
import { createCushionClient } from "../../libs/client";
import { AppError, ERROR_CODES } from "../../libs/errors";
import { getQRCodeURL } from "../../libs/ps";
import { initSDK } from "../../libs/sdk";

const searchSchema = z.object({
  t: z.string().optional(),
});

function generateUrl(serviceId: string, storeId: string, t?: string) {
  const tag = t && t.length > 0 ? `?t=${t}` : "";
  return getQRCodeURL(serviceId, `/pay/${storeId}${tag}`);
}

export const Route = createFileRoute("/s/$storeId")({
  validateSearch: (search) => searchSchema.parse(search),
  loaderDeps: ({ search: { t } }) => ({ t }),
  loader: async ({ params: { storeId }, deps: { t } }) => {
    const client = createCushionClient();
    const response = await client.getAvailableTenants({ storeId });
    const { store, tenants } = response;

    if (!store) {
      throw new Error("store not found");
    }

    if (tenants.length === 1) {
      // ミニアプリが1つしかない場合、そのミニアプリを開く
      const url = generateUrl(tenants[0].serviceId, storeId, t);
      location.href = url;
    }

    return { storeId, tenants, store, t: t };
  },
  component: AppSelector,
});

const serviceId = import.meta.env.VITE_CUSHION_SERVICE_ID as string;

// フォールバック用のテナントID
// 複数のテナントに対応しているQRコードだが、どのミニアプリもサブスクしていない場合、このミニアプリを開く
const fallbackServiceId = import.meta.env.VITE_FALLBACK_TENANT_ID as string;

const TENANT_PPID_RESOURCE_ID_MAP = {
  miyagi: import.meta.env.VITE_MIYAGI_PPID_RESOURCE_ID as string,
  marumori: import.meta.env.VITE_MARUMORI_PPID_RESOURCE_ID as string,
  demo: import.meta.env.VITE_DEMO_PPID_RESOURCE_ID as string,
};

function AppSelector() {
  const { store, tenants, t } = Route.useLoaderData();
  const [selectedServiceId, setSelectedServiceId] = useState<string | null>(
    null,
  );
  const [loading, setLoading] = useState(false);
  const [availableTenants, setAvailableTenants] = useState<Tenant[]>([]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const sdk = await initSDK(serviceId);

      const tenantPPIDResourceIds = tenants
        .map(
          (tenant) =>
            TENANT_PPID_RESOURCE_ID_MAP[
              tenant.slug as keyof typeof TENANT_PPID_RESOURCE_ID_MAP
            ],
        )
        .filter((id) => id !== undefined);

      const response = await _internal_2(sdk, {
        resourceIds: tenantPPIDResourceIds,
        force: true,
      });

      if (response.result === "error") {
        throw new AppError(ERROR_CODES.SDK_INTERNAL_2_ERROR);
      }

      const availableTenants = response.values
        .map((value) => {
          if (value.value === null) {
            return null;
          }

          const tenantSlug = Object.entries(TENANT_PPID_RESOURCE_ID_MAP).find(
            ([_, id]) => id === value.id,
          )?.[0];

          const tenant = tenants.find((tenant) => tenant.slug === tenantSlug);

          return tenant ?? null;
        })
        .filter((tenant) => tenant !== null);

      setAvailableTenants(availableTenants);

      if (availableTenants.length === 0) {
        // どのミニアプリもサブスクしていない場合、フォールバック用のミニアプリを開く
        // ポイントも持っていないはずなため、支払い画面ではなくトップページ
        location.href = getQRCodeURL(fallbackServiceId);
        return;
      }

      setLoading(false);
    })();
  }, [tenants]);

  const handleSubmit = useCallback(() => {
    if (!selectedServiceId) {
      return;
    }

    location.href = generateUrl(selectedServiceId, store.id, t);
  }, [selectedServiceId, store.id, t]);

  if (loading) {
    return (
      <>
        <Header title="ポイントアプリ選択" />
        <div
          className={css({
            bg: "background.background",
            minH: "100vh",
            pt: "48px",
            px: "24px",
            pb: "24px",
          })}
        >
          <div
            className={css({
              mt: "24px",
              height: "24px",
              bg: "surface.tertiary",
              borderRadius: "4px",
              animation: "shine 1.5s infinite linear",
              backgroundImage:
                "linear-gradient(90deg, transparent 0%, rgba(255, 255, 255, 0.4) 50%, transparent 100%)",
              backgroundSize: "200% 100%",
              width: "80%",
              mx: "auto",
            })}
          />

          <div
            className={css({
              mt: "32px",
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            })}
          >
            <div
              className={css({
                height: "20px",
                width: "30%",
                bg: "surface.tertiary",
                borderRadius: "4px",
                animation: "shine 1.5s infinite linear",
                backgroundImage:
                  "linear-gradient(90deg, transparent 0%, rgba(255, 255, 255, 0.4) 50%, transparent 100%)",
                backgroundSize: "200% 100%",
              })}
            />

            <div
              className={css({
                display: "flex",
                alignItems: "center",
                gap: "16px",
                border: "1px solid",
                borderColor: "border.primary",
                borderRadius: "12px",
                p: "16px",
              })}
            >
              <div
                className={css({
                  width: "32px",
                  height: "32px",
                  bg: "surface.tertiary",
                  borderRadius: "4px",
                  animation: "shine 1.5s infinite linear",
                  backgroundImage:
                    "linear-gradient(90deg, transparent 0%, rgba(255, 255, 255, 0.4) 50%, transparent 100%)",
                  backgroundSize: "200% 100%",
                })}
              />
              <div
                className={css({
                  height: "20px",
                  width: "40%",
                  bg: "surface.tertiary",
                  borderRadius: "4px",
                  animation: "shine 1.5s infinite linear",
                  backgroundImage:
                    "linear-gradient(90deg, transparent 0%, rgba(255, 255, 255, 0.4) 50%, transparent 100%)",
                  backgroundSize: "200% 100%",
                })}
              />
            </div>
          </div>

          <div
            className={css({
              mt: "32px",
              display: "grid",
              gridTemplateColumns: "repeat(2, 1fr)",
              gap: "16px",
            })}
          >
            {[1, 2].map((i) => (
              <div
                key={i}
                className={css({
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "8px",
                  borderStyle: "solid",
                  borderWidth: "1px",
                  borderColor: "border.primary",
                  borderRadius: "16px",
                  p: "16px",
                  bg: "white",
                })}
              >
                <div
                  className={css({
                    width: "92px",
                    height: "92px",
                    bg: "surface.tertiary",
                    borderRadius: "16px",
                    animation: "shine 1.5s infinite linear",
                    backgroundImage:
                      "linear-gradient(90deg, transparent 0%, rgba(255, 255, 255, 0.4) 50%, transparent 100%)",
                    backgroundSize: "200% 100%",
                  })}
                />
                <div
                  className={css({
                    height: "20px",
                    width: "60%",
                    bg: "surface.tertiary",
                    borderRadius: "4px",
                    animation: "shine 1.5s infinite linear",
                    backgroundImage:
                      "linear-gradient(90deg, transparent 0%, rgba(255, 255, 255, 0.4) 50%, transparent 100%)",
                    backgroundSize: "200% 100%",
                  })}
                />
              </div>
            ))}
          </div>

          <div
            className={css({
              height: "44px",
              bg: "surface.disable",
              w: "100%",
              borderRadius: "12px",
              mt: "32px",
            })}
          />
        </div>
      </>
    );
  }

  return (
    <>
      <Header title="ポイントアプリ選択" />
      <div
        className={css({
          bg: "background.background",
          minH: "100vh",
          pt: "48px",
          px: "24px",
          pb: "24px",
        })}
      >
        <div
          className={css({
            mt: "24px",
            fontSize: "24px",
            textAlign: "center",
          })}
        >
          どちらのポイントアプリで
          <br />
          支払いますか？
        </div>

        {store && (
          <div
            className={css({
              display: "flex",
              flexDirection: "column",
              gap: "16px",
              mt: "32px",
            })}
          >
            <div
              className={css({
                color: "text.secondary",
              })}
            >
              お支払い店舗
            </div>

            <div
              className={css({
                display: "flex",
                alignItems: "center",
                gap: "16px",
                border: "1px solid",
                borderColor: "border.primary",
                borderRadius: "12px",
                p: "16px",
              })}
            >
              <div
                className={css({
                  width: "32px",
                })}
              >
                <img
                  src={store.iconUrl}
                  alt={store.name}
                  className={css({
                    w: "100%",
                  })}
                />
              </div>
              <div
                className={css({
                  color: "text.primary",
                })}
              >
                {store.name}
              </div>
            </div>
          </div>
        )}

        <div
          className={css({
            mt: "32px",
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gap: "16px",
          })}
        >
          {availableTenants.map((tenant) => {
            const isSelected = selectedServiceId === tenant.serviceId;
            return (
              <button
                key={tenant.id}
                onClick={() => setSelectedServiceId(tenant.serviceId)}
                className={css({
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "8px",
                  borderStyle: "solid",
                  borderWidth: "1px",
                  borderColor: isSelected
                    ? "surface.accentPrimary"
                    : "border.primary",
                  borderRadius: "16px",
                  p: "16px",
                  bg: "white",
                  width: "100%",
                  cursor: "pointer",
                  _hover: {
                    borderColor: "surface.accentPrimary",
                  },
                })}
                type="button"
              >
                <div
                  className={css({
                    width: "92px",
                    height: "92px",
                  })}
                >
                  <img
                    src={tenant.iconUrl}
                    alt={tenant.name}
                    className={css({
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                      borderRadius: "16px",
                    })}
                  />
                </div>
                <div>{tenant.name}</div>
              </button>
            );
          })}
        </div>

        <button
          className={css({
            bg: selectedServiceId ? "surface.accentPrimary" : "surface.disable",
            w: "100%",
            p: "12px",
            borderRadius: "12px",
            color: selectedServiceId ? "white" : "text.disable",
            mt: "32px",
            cursor: selectedServiceId ? "pointer" : "not-allowed",
          })}
          type="button"
          disabled={!selectedServiceId}
          onClick={handleSubmit}
        >
          決定
        </button>
      </div>
    </>
  );
}
