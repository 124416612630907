import { CushionService } from "schema/gen/es/chiikipoint/cushion/v2/service_connect.js";
import {
  CampaignLottery_Result,
  Transaction_Type,
} from "schema/gen/es/chiikipoint/model/v2/model_pb";
import { AccountService } from "schema/gen/es/chiikipoint/wallet/v2/account_service_connect.js";
import {
  GetTenantResponse,
  type LoginRequest,
  LoginResponse,
} from "schema/gen/es/chiikipoint/wallet/v2/account_service_pb.js";
import { WalletService } from "schema/gen/es/chiikipoint/wallet/v2/service_connect.js";

import {
  type ApplyCampaignRequest,
  ApplyCampaignResponse,
  type CreateMyInviterRequest,
  CreateMyInviterResponse,
  GetAllCitiesResponse,
  GetCategoriesResponse,
  GetMyBalancesResponse,
  GetMyInvitationResponse,
  type GetMyTransactionRequest,
  GetMyTransactionResponse,
  GetMyTransactionsResponse,
  GetPrimaryCampaignFundResponse,
  type GetStoreRequest,
  GetStoreResponse,
  GetStoresResponse,
  type PayRequest,
  PayResponse,
} from "schema/gen/es/chiikipoint/wallet/v2/service_pb";

import { createRouterTransport } from "@connectrpc/connect";
import * as jose from "jose";
import {
  type GetAvailableTenantsRequest,
  GetAvailableTenantsResponse,
} from "schema/gen/es/chiikipoint/cushion/v2/service_pb";
import { getAppEnv, getTenantSlug } from "./utils";

const chiikiConfig = {
  demo: {
    "dev/mock": {
      name: "デモ地域ポイント",
      serviceId: "b43596db-9481-432f-ae7f-d4a89de9af7c",
      iconUrl: "/misc/icons/demo.png",
    },
    "prod/mock": {
      name: "デモ地域ポイント",
      serviceId: "d4336d16-3e2e-42ec-bee5-d5527121e422",
      iconUrl: "/misc/icons/demo.png",
    },
  },
  marumori: {
    "dev/mock": {
      name: "丸森ポイント",
      serviceId: "05602033-97a2-42e9-ba52-95704486936e",
      iconUrl: "/misc/icons/marumori.png",
    },
    "prod/mock": {
      name: "丸森ポイント",
      serviceId: "bb885475-76d6-45f4-b8c0-e237f78949ab",
      iconUrl: "/misc/icons/marumori.png",
    },
  },
  miyagi: {
    "dev/mock": {
      name: "みやぎポイント",
      serviceId: "1895667b-5864-466a-81bb-19fe5ce55f14",
      iconUrl: "/misc/icons/miyagi.png",
    },
    "prod/mock": {
      name: "みやぎポイント",
      serviceId: "e21c1553-1cb9-46ee-a8ad-8f232756e06a",
      iconUrl: "/misc/icons/miyagi.png",
    },
  },
} as const;

function getMockTenantByUrl() {
  const env = getAppEnv();
  const slug = getTenantSlug();

  const chiiki = chiikiConfig[slug as keyof typeof chiikiConfig];

  if (!chiiki) {
    throw new Error("chiiki not found");
  }

  const tenant = chiiki[env as keyof typeof chiiki];

  if (!tenant) {
    throw new Error("chiiki env not found");
  }

  return { slug, ...tenant };
}

function generateToken() {
  const secret = new TextEncoder().encode("mock-secret-key");
  const jwt = new jose.SignJWT({
    user_id: "6ca9d764-5d2f-405a-b0a2-656eeac151ac",
  })
    .setProtectedHeader({ alg: "HS256", typ: "JWT" })
    .setExpirationTime("1m") // 1分で有効期限切れ
    .sign(secret);

  return jwt;
}

export const accountServiceMockTransport = createRouterTransport(
  ({ service }) => {
    service(AccountService, {
      getTenant: () => {
        const tenant = getMockTenantByUrl();
        return new GetTenantResponse({
          tenant: {
            ...tenant,
            id: "1",
            contact: "0120-1234-5678",
          },
        });
      },
      login: async (_: LoginRequest) =>
        new LoginResponse({
          token: await generateToken(),
        }),
    });
  },
  {
    transport: {
      interceptors: [
        (next) => async (req) => {
          // 通信遅延をシミュレート
          // await new Promise((resolve) => setTimeout(resolve, 200));
          return next(req);
        },
      ],
    },
  },
);

export const walletServiceMockTransport = createRouterTransport(
  ({ service }) => {
    service(WalletService, {
      getMyBalances: () => {
        const slug = getTenantSlug();
        if (slug === "marumori") {
          return new GetMyBalancesResponse({
            balances: [
              {
                addId: "0191f997-35db-7d43-a6a0-652120513df6",
                userId: "0191f997-54e2-7b21-b9fd-f14fb7695bf9",
                fundSourceId: "0191f997-b5fc-7732-9303-a06f08183c09",
                amount: BigInt(2000),
                expireAt: {
                  seconds: BigInt(1740754799),
                  nanos: 0,
                },
              },
            ],
          });
        }
        return new GetMyBalancesResponse({
          balances: [
            {
              addId: "0191f997-35db-7d43-a6a0-652120513df6",
              userId: "0191f997-54e2-7b21-b9fd-f14fb7695bf9",
              fundSourceId: "0191f997-b5fc-7732-9303-a06f08183c09",
              amount: BigInt(2000),
              expireAt: {
                seconds: BigInt(1740754799),
                nanos: 0,
              },
            },
            {
              addId: "0191f997-35db-7d43-a6a0-652120513df6",
              userId: "0191f997-54e2-7b21-b9fd-f14fb7695bf9",
              fundSourceId: "0191f997-b5fc-7732-9303-a06f08183c09",
              amount: BigInt(1000),
              expireAt: {
                seconds: BigInt(1740754799),
                nanos: 0,
              },
            },
          ],
        });
      },

      getMyTransactions: () => {
        // return new GetMyTransactionsResponse({
        //   transactionGroups: [],
        // });

        return new GetMyTransactionsResponse({
          transactionGroups: [
            {
              id: "payment-1",
              transactionDetails: [
                {
                  transaction: {
                    id: "df880a2a-0101-45a7-a1cc-e2f4f7c1e4b7",
                    groupId: "1",
                    userId: "1",
                    type: Transaction_Type.PAYMENT,
                    balanceAddId: "1",
                    amount: BigInt(-100),
                    fundSourceId: "1",
                    reason: "支払い",
                    createdAt: {
                      seconds: BigInt(1739634210),
                      nanos: 0,
                    },
                  },
                  store: {
                    id: "1",
                    name: "みやポマート",
                    fullAddress: "宮城県仙台市青葉区一番町１丁目３−２",
                    city: "宮城県仙台市青葉区",
                    location: {
                      latitude: 38.25783587179756,
                      longitude: 140.87284595831724,
                    },
                    category: "コンビニ",
                    iconUrl: "/misc/storefront.png",
                  },
                },
              ],
            },
            {
              id: "payment-2",
              transactionDetails: [
                {
                  transaction: {
                    id: "df880a2a-0101-45a7-a1cc-e2f4f7c1e4b7",
                    groupId: "1",
                    userId: "1",
                    type: Transaction_Type.PAYMENT,
                    balanceAddId: "1",
                    amount: BigInt(-1000),
                    fundSourceId: "1",
                    reason: "支払い",
                    createdAt: {
                      seconds: BigInt(1737967410),
                      nanos: 0,
                    },
                  },
                  store: {
                    id: "1",
                    name: "みやポマート",
                    fullAddress: "宮城県仙台市青葉区一番町１丁目３−２",
                    city: "宮城県仙台市青葉区",
                    location: {
                      latitude: 38.25783587179756,
                      longitude: 140.87284595831724,
                    },
                    category: "コンビニ",
                    iconUrl: "/misc/storefront.png",
                  },
                },
              ],
            },
            {
              id: "payment-3",
              transactionDetails: [
                {
                  transaction: {
                    id: "cd8b0791-7162-4918-9666-78086c014c40",
                    groupId: "1",
                    userId: "1",
                    type: Transaction_Type.PAYMENT,
                    balanceAddId: "1",
                    amount: BigInt(-500),
                    fundSourceId: "1",
                    reason: "支払い",
                    createdAt: {
                      seconds: BigInt(1737957410),
                      nanos: 0,
                    },
                  },
                  store: {
                    id: "1",
                    name: "みやポスーパー",
                    fullAddress: "宮城県仙台市青葉区一番町１丁目３−２",
                    city: "宮城県仙台市青葉区",
                    location: {
                      latitude: 38.25783587179756,
                      longitude: 140.87284595831724,
                    },
                    category: "スーパー",
                    iconUrl: "/misc/storefront.png",
                  },
                },
              ],
            },
            {
              id: "grant-1",
              transactionDetails: [
                {
                  transaction: {
                    id: "fedc1830-8f3f-40ef-b97e-015fad05bc74",
                    groupId: "1",
                    userId: "1",
                    type: Transaction_Type.GRANT,
                    balanceAddId: "1",
                    amount: BigInt(3000),
                    fundSourceId: "1",
                    reason: "ポイント抽選当選",
                    createdAt: {
                      seconds: BigInt(1736089200),
                      nanos: 0,
                    },
                  },
                  fundSource: {
                    id: "0191f997-b5fc-7732-9303-a06f08183c09",
                    name: "ポイント抽選",
                  },
                },
              ],
            },
          ],
        });
      },
      getMyTransaction: (req: GetMyTransactionRequest) => {
        if (req.transactionGroupId.startsWith("payment")) {
          return new GetMyTransactionResponse({
            transactionGroup: {
              id: "df880a2a",
              transactionDetails: [
                {
                  transaction: {
                    id: "df880a2a-0101-45a7-a1cc-e2f4f7c1e4b7",
                    groupId: "1",
                    userId: "1",
                    type: Transaction_Type.PAYMENT,
                    balanceAddId: "1",
                    amount: BigInt(-100),
                    fundSourceId: "1",
                    reason: "支払い",
                    createdAt: {
                      seconds: BigInt(1739634210),
                      nanos: 0,
                    },
                  },
                  store: {
                    id: "1",
                    name: "みやポマート",
                    fullAddress: "宮城県仙台市青葉区一番町１丁目３−２",
                    city: "宮城県仙台市青葉区",
                    iconUrl: "/misc/storefront.png",
                    location: {
                      latitude: 38.25783587179756,
                      longitude: 140.87284595831724,
                    },
                  },
                },
              ],
            },
          });
        }

        if (req.transactionGroupId.startsWith("grant")) {
          return new GetMyTransactionResponse({
            transactionGroup: {
              id: "df880a2a",
              transactionDetails: [
                {
                  transaction: {
                    id: "fedc1830-8f3f-40ef-b97e-015fad05bc74",
                    groupId: "1",
                    userId: "1",
                    type: Transaction_Type.GRANT,
                    balanceAddId: "1",
                    amount: BigInt(3000),
                    fundSourceId: "1",
                    reason: "ポイント抽選当選",
                    createdAt: {
                      seconds: BigInt(1736089200),
                      nanos: 0,
                    },
                  },
                  fundSource: {
                    id: "0191f997-b5fc-7732-9303-a06f08183c09",
                    name: "ポイント抽選",
                  },
                },
              ],
            },
          });
        }
        throw new Error("transaction group not found");
      },
      getCategories: () =>
        new GetCategoriesResponse({
          categories: [
            "スーパー",
            "コンビニ",
            "ドラッグストア",
            "ガソリンスタンド",
            "その他の専門店",
            "飲食店",
            "居酒屋",
            "ホテル・旅館",
            "その他のサービス業",
          ],
        }),
      getAllCities: () => {
        if (getTenantSlug() === "marumori") {
          return new GetAllCitiesResponse({
            cities: ["宮城県伊具郡丸森町"],
          });
        }
        return new GetAllCitiesResponse({
          cities: [
            "宮城県仙台市青葉区",
            "宮城県仙台市宮城野区",
            "宮城県仙台市若林区",
            "宮城県仙台市太白区",
            "宮城県仙台市泉区",
            "宮城県石巻市",
            "宮城県塩竈市",
            "宮城県気仙沼市",
            "宮城県白石市",
            "宮城県名取市",
            "宮城県角田市",
            "宮城県多賀城市",
            "宮城県岩沼市",
            "宮城県登米市",
            "宮城県栗原市",
            "宮城県東松島市",
            "宮城県大崎市",
            "宮城県富谷市",
            "宮城県刈田郡蔵王町",
            "宮城県刈田郡七ヶ宿町",
            "宮城県柴田郡大河原町",
            "宮城県柴田郡村田町",
            "宮城県柴田郡柴田町",
            "宮城県柴田郡川崎町",
            "宮城県伊具郡丸森町",
            "宮城県亘理郡亘理町",
            "宮城県亘理郡山元町",
            "宮城県宮城郡松島町",
            "宮城県宮城郡七ヶ浜町",
            "宮城県宮城郡利府町",
            "宮城県黒川郡大和町",
            "宮城県黒川郡大郷町",
            "宮城県黒川郡大衡村",
            "宮城県加美郡色麻町",
          ],
        });
      },
      getStores: () =>
        new GetStoresResponse({
          stores: [
            {
              id: "0191fa0d-87ac-7454-b2dd-674cbc3960e0",
              name: "みやポスーパー",
              fullAddress: "宮城県仙台市青葉区川内２６-1-1",
              city: "宮城県仙台市青葉区",
              location: {
                latitude: 38.25611519745144,
                longitude: 140.85658498337693,
              },
              category: "スーパー",
              iconUrl: "/misc/storefront.png",
            },
            {
              id: "0191fa54-bc14-7679-8d40-d8a31fa68a77",
              name: "みやポマート",
              fullAddress: "宮城県仙台市青葉区一番町１丁目３−２",
              city: "宮城県仙台市青葉区",
              location: {
                latitude: 38.25783587179756,
                longitude: 140.87284595831724,
              },
              category: "コンビニ",
              iconUrl: "/misc/storefront.png",
            },
            {
              id: "0191fa0d-1ef4-7303-b2db-d580b98d1169",
              name: "ドラッグみやポ",
              fullAddress: "宮城県仙台市青葉区一番町１丁目３−１",
              city: "宮城県仙台市青葉区",
              location: {
                latitude: 38.25783587179756,
                longitude: 140.87284595831724,
              },
              category: "ドラッグストア",
              iconUrl: "/misc/storefront.png",
            },
            {
              id: "0191f9f7-3047-795d-af14-2146e59151eb",
              name: "みやポ食堂",
              fullAddress: "宮城県仙台市青葉区一番町４丁目３−２２",
              city: "宮城県仙台市青葉区",
              location: {
                latitude: 38.26350451709948,
                longitude: 140.87092366443375,
              },
              category: "飲食店",
              iconUrl: "/misc/storefront.png",
            },
            {
              id: "0191f9ec-9019-763f-8fa1-0b8ea46c0dfe",
              name: "Hotelみやポ",
              fullAddress:
                "宮城県仙台市青葉区一番町４丁目１−１ オークツリー 一番町 1F",
              city: "宮城県仙台市青葉区",
              location: {
                latitude: 38.26304020197413,
                longitude: 140.8729801571959,
              },
              category: "旅館・ホテル",
              iconUrl: "/misc/storefront.png",
            },
            {
              id: "bed51b92-95a6-4a07-99f4-a19e574c50ea",
              name: "まるもりスーパー",
              fullAddress: "〒981-2152 宮城県伊具郡丸森町鳥屋１２０番地",
              city: "宮城県伊具郡丸森町",
              location: {
                latitude: 38.25611519745144,
                longitude: 140.85658498337693,
              },
              category: "スーパー",
              iconUrl: "/misc/storefront.png",
            },
            {
              id: "d90ef3b4-bd7c-40d0-8aa2-e221f1233ac5",
              name: "まるもりマート",
              fullAddress: "〒981-2152 宮城県伊具郡丸森町鳥屋１２０番地",
              city: "宮城県伊具郡丸森町",
              location: {
                latitude: 38.25783587179756,
                longitude: 140.87284595831724,
              },
              category: "コンビニ",
              iconUrl: "/misc/storefront.png",
            },
            {
              id: "8b8a9f9a-f6f2-40e0-a15e-af057b4eeff1",
              name: "ドラッグまるもり",
              fullAddress: "〒981-2152 宮城県伊具郡丸森町鳥屋１２０番地",
              city: "宮城県伊具郡丸森町",
              location: {
                latitude: 38.25783587179756,
                longitude: 140.87284595831724,
              },
              category: "ドラッグストア",
              iconUrl: "/misc/storefront.png",
            },
            {
              id: "f5ad85e1-5c05-4dde-9a79-80ac88e9ab67",
              name: "まるもり食堂",
              fullAddress: "〒981-2152 宮城県伊具郡丸森町鳥屋１２０番地",
              city: "宮城県伊具郡丸森町",
              location: {
                latitude: 38.26350451709948,
                longitude: 140.87092366443375,
              },
              category: "飲食店",
              iconUrl: "/misc/storefront.png",
            },
            {
              id: "7bd9ef7a-6618-4c07-81f6-5537ab25e751",
              name: "Hotelまるもり",
              fullAddress: "〒981-2152 宮城県伊具郡丸森町鳥屋１２０番地",
              city: "宮城県伊具郡丸森町",
              location: {
                latitude: 38.26304020197413,
                longitude: 140.8729801571959,
              },
              category: "旅館・ホテル",
              iconUrl: "/misc/storefront.png",
            },
          ],
        }),
      getStore: (_: GetStoreRequest) => {
        if (getTenantSlug() === "marumori") {
          return new GetStoreResponse({
            store: {
              id: "d90ef3b4-bd7c-40d0-8aa2-e221f1233ac5",
              name: "まるもりマート",
              fullAddress: "〒981-2152 宮城県伊具郡丸森町鳥屋１２０番地",
              city: "宮城県伊具郡丸森町",
              location: {
                latitude: 38.25783587179756,
                longitude: 140.87284595831724,
              },
              category: "コンビニ",
              iconUrl: "/misc/storefront.png",
            },
            companyName: "まるもり事業者",
          });
        }
        return new GetStoreResponse({
          store: {
            id: "0191fa54-bc14-7679-8d40-d8a31fa68a77",
            name: "みやポマート",
            fullAddress: "宮城県仙台市青葉区一番町１丁目３−２",
            city: "宮城県仙台市青葉区",
            location: {
              latitude: 38.25783587179756,
              longitude: 140.87284595831724,
            },
            category: "コンビニ",
            iconUrl: "/misc/default-store-icon.png",
          },
          companyName: "まるもり事業者",
        });
      },
      getMyInvitation: () =>
        new GetMyInvitationResponse({
          invitationCode: "2JSZtc",
          invitees: [
            {
              id: "019204ea-7c3a-7f9d-8f5c-7f5d3c6f0b8d",
              inviteeUserId: "019204eb-e359-7291-8643-28c5a37a2e2e",
            },
          ],
          isCampaignOver: false,
        }),
      createMyInviter: (_: CreateMyInviterRequest) =>
        new CreateMyInviterResponse({}),
      getPrimaryCampaignFund: () =>
        new GetPrimaryCampaignFundResponse({
          fundSource: {
            id: "019204eb-e359-7291-8643-28c5a37a2e2e",
            name: "ポイント抽選",
            limitTimes: BigInt(160000),
            priorityWeight: BigInt(0),
          },
        }),
      applyCampaign: (_: ApplyCampaignRequest) => {
        if (getTenantSlug() === "marumori") {
          return new ApplyCampaignResponse({
            campaignLottery: {
              userId: "019204eb-e359-7291-8643-28c5a37a2e2e",
              wonBalance: BigInt(2000),
              result: CampaignLottery_Result.WON,
              fundSourceId: "019204eb-e359-7291-8643-28c5a37a2e2e",
            },
          });
        }
        return new ApplyCampaignResponse({
          campaignLottery: {
            userId: "019204eb-e359-7291-8643-28c5a37a2e2e",
            wonBalance: BigInt(3000),
            result: CampaignLottery_Result.WON,
            fundSourceId: "019204eb-e359-7291-8643-28c5a37a2e2e",
          },
        });
      },
      pay: (_: PayRequest) =>
        new PayResponse({
          transactionGroup: {
            id: "df880a2a",
            transactionDetails: [
              {
                transaction: {
                  id: "df880a2a-0101-45a7-a1cc-e2f4f7c1e4b7",
                  groupId: "1",
                  userId: "1",
                  type: Transaction_Type.PAYMENT,
                  balanceAddId: "1",
                  amount: BigInt(-100),
                  fundSourceId: "1",
                  reason: "支払い",
                  createdAt: {
                    seconds: BigInt(1739634210),
                    nanos: 0,
                  },
                },
                store: {
                  id: "1",
                  name: "みやポマート",
                  fullAddress: "宮城県仙台市青葉区一番町１丁目３−２",
                  city: "宮城県仙台市青葉区",
                  iconUrl: "/misc/storefront.png",
                  location: {
                    latitude: 38.25783587179756,
                    longitude: 140.87284595831724,
                  },
                },
              },
            ],
          },
        }),
    });
  },
  {
    transport: {
      interceptors: [
        (next) => async (req) => {
          // 通信遅延をシミュレート
          // await new Promise((resolve) => setTimeout(resolve, 20000));
          return next(req);
        },
      ],
    },
  },
);

function getTenants() {
  const env = getAppEnv();

  const miyagi =
    chiikiConfig["miyagi" as const][
      env as keyof (typeof chiikiConfig)["miyagi"]
    ];

  const marumori =
    chiikiConfig["marumori" as const][
      env as keyof (typeof chiikiConfig)["marumori"]
    ];

  return [
    { id: "miyagi", slug: "miyagi", ...miyagi },
    { id: "marumori", slug: "marumori", ...marumori },
  ];
}

export const cushionServiceMockTransport = createRouterTransport(
  ({ service }) => {
    service(CushionService, {
      getAvailableTenants: (_: GetAvailableTenantsRequest) =>
        new GetAvailableTenantsResponse({
          tenants: getTenants(),
          store: {
            id: "0191fa54-bc14-7679-8d40-d8a31fa68a77",
            name: "みやポマート",
            fullAddress: "宮城県仙台市青葉区一番町１丁目３−２",
            city: "宮城県仙台市青葉区",
            category: "コンビニ",
            iconUrl: "/misc/default-store-icon.png",
          },
        }),
    });
  },
);
