import { Link, createFileRoute, useNavigate } from "@tanstack/react-router";
import { memo, useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import Close from "~icons/material-symbols/close";
import Info from "~icons/material-symbols/info-outline";
import Person from "~icons/material-symbols/person-outline";
import { css } from "../../../styled-system/css";
import demo from "../../assets/demo/icon.png";
import marumori from "../../assets/marumori/card-small.webp";
import bousaiMiyapo from "../../assets/miyagi/bousai-miyapo.png";
import miyagi from "../../assets/miyagi/icon-small.webp";
import { Card } from "../../components/card";
import Header from "../../components/header";
import { MIYAGI_BOUSAI_SERVICE_ID } from "../../config";
import type { TenantWithFeature } from "../../libs/connect";
import { AppError, ERROR_CODES } from "../../libs/errors";
import { encodeUUID } from "../../libs/ps";
import { formatNumber, isMockEnv } from "../../libs/utils";

export const Route = createFileRoute("/_authed/")({
  loader: async ({ location, context: { client, tenant } }) => {
    const balance = await client.getMyBalances({}).catch((error) => {
      throw new AppError(
        ERROR_CODES.CONNECT_GET_MY_BALANCES_FAILED,
        error.message,
        {
          originalError: error,
        },
      );
    });
    const totalAmount = balance.balances.reduce(
      (acc, balance) => acc + Number(balance.amount),
      0,
    );
    const nearestExpireAt =
      balance.balances.length > 0
        ? balance.balances.reduce((nearest, current) => {
            if (
              !nearest ||
              (current.expireAt && current.expireAt.seconds < nearest.seconds)
            ) {
              return current.expireAt;
            }
            return nearest;
          }, balance.balances[0].expireAt)
        : null;
    const nearestExpireAtDate = nearestExpireAt
      ? new Date(Number(nearestExpireAt.seconds) * 1000)
      : null;

    const primaryCampaignFund = await client
      .getPrimaryCampaignFund({})
      .catch((error) => {
        throw new AppError(
          ERROR_CODES.CONNECT_GET_PRIMARY_CAMPAIGN_FUND_FAILED,
          error.message,
          {
            originalError: error,
          },
        );
      });
    const canApplyToPrimaryCampaign =
      tenant.feature.lottely && !primaryCampaignFund.isApplied;
    const showInvitationCard =
      tenant.feature.invitation && primaryCampaignFund.isApplied;

    const toastState = location.state?.toast;

    if (toastState) {
      if (toastState.type === "success") {
        toast.success(toastState.message);
      }
      if (toastState.type === "error") {
        toast.error(toastState.message);
      }
    }

    return {
      totalAmount,
      nearestExpireAtDate,
      tenant,
      canApplyToPrimaryCampaign,
      showInvitationCard,
    };
  },
  component: Home,
});

type ModalProps = {
  tenant: TenantWithFeature;
  onClickCancel: () => void;
};

const Modal = memo(({ onClickCancel, tenant }: ModalProps) => {
  const navigate = useNavigate();

  const handleOutsideClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    // クリックがバックドロップ（背景）のみであれば、閉じる処理を実行
    if (e.target === e.currentTarget) {
      onClickCancel();
    }
  };

  const DefaultLotteryModal = () => {
    return (
      <div
        className={css({
          bg: "white",
          roundedTop: "2xl",
          px: "16px",
          pt: "16px",
          pb: "48px",
          display: "flex",
          flexDir: "column",
          gap: "16px",
          position: "fixed",
          bottom: 0,
          left: 0,
          width: "100%",
          zIndex: 999,
        })}
      >
        <div>
          <div
            className={css({
              display: "flex",
            })}
          >
            <div
              className={css({
                color: "text.primary",
                fontSize: "18px",
                fontWeight: 500,
              })}
            >
              ポイント抽選に参加しましょう！
            </div>

            <button
              type="button"
              className={css({
                color: "text.primary",
                border: "none",
                cursor: "pointer",
                ml: "auto",
                bg: "action.hover",
                rounded: "full",
                p: "4px",
              })}
              onClick={onClickCancel}
            >
              <Close />
            </button>
          </div>
        </div>
        <p>その場でポイント抽選の結果を確認することができます。</p>

        <div
          className={css({ display: "flex", gap: "10px", flexDir: "column" })}
        >
          <button
            type="button"
            className={css({
              bg: "surface.accentPrimary",
              color: "white",
              rounded: "2xl",
              p: "12px",
            })}
            onClick={() => {
              navigate({ to: "/lottery/processing" });
              onClickCancel();
            }}
          >
            抽選に参加
          </button>
          <button
            type="button"
            className={css({
              bg: "rgba(229, 231, 235, 0.50)",
              rounded: "2xl",
              p: "12px",
            })}
            onClick={onClickCancel}
          >
            あとで
          </button>
        </div>
      </div>
    );
  };

  const MiyagiLotteryModal = () => {
    const handleClickApplyWithBousai = useCallback(() => {
      if (isMockEnv()) {
        navigate({ to: "/lottery/bousai/mock" });
      } else {
        location.href = `https://p8n.jp/i/${encodeUUID(MIYAGI_BOUSAI_SERVICE_ID)}?u=${encodeURIComponent("#/campaign/miyagi-point")}`;
      }

      onClickCancel();
    }, [onClickCancel]);

    return (
      <div
        className={css({
          bg: "white",
          roundedTop: "2xl",
          px: "16px",
          pt: "16px",
          pb: "48px",
          display: "flex",
          flexDir: "column",
          gap: "16px",
          position: "fixed",
          bottom: 0,
          left: 0,
          width: "100%",
          zIndex: 999,
        })}
      >
        <div>
          <div
            className={css({
              display: "flex",
            })}
          >
            <div
              className={css({
                color: "text.primary",
                fontSize: "18px",
                fontWeight: 500,
              })}
            >
              ポイント抽選に参加しましょう！
            </div>

            <button
              type="button"
              className={css({
                color: "text.primary",
                border: "none",
                cursor: "pointer",
                ml: "auto",
                bg: "action.hover",
                rounded: "full",
                p: "4px",
              })}
              onClick={onClickCancel}
            >
              <Close />
            </button>
          </div>
          <p
            className={css({
              color: "text.secondary",
              fontSize: "14px",
            })}
          >
            まずは「みやぎ防災ミニアプリ」に登録
          </p>
        </div>
        <p>
          ポイント抽選への参加はみやぎ防災ミニアプリの登録が必要です。登録完了後、その場でポイント抽選の結果を確認することができます。
        </p>
        <div
          className={css({
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          })}
        >
          <img
            src={bousaiMiyapo}
            alt="防災アプリに登録"
            className={css({
              w: "176px",
            })}
          />
        </div>
        <div
          className={css({ display: "flex", gap: "10px", flexDir: "column" })}
        >
          <button
            type="button"
            className={css({
              bg: "surface.accentPrimary",
              color: "white",
              rounded: "2xl",
              p: "12px",
            })}
            onClick={handleClickApplyWithBousai}
          >
            防災に登録して抽選に参加
          </button>
          <button
            type="button"
            className={css({
              bg: "rgba(229, 231, 235, 0.50)",
              rounded: "2xl",
              p: "12px",
            })}
            onClick={onClickCancel}
          >
            あとで
          </button>
        </div>
      </div>
    );
  };

  return (
    <div
      className={css({
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        bg: "rgba(0, 0, 0, 0.1)",
        zIndex: 888,
      })}
    >
      {/* biome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
      <div
        className={css({
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: 999,
          backdropFilter: "blur(3px)",
          transition: "transform 0.3s ease-out",
          transform: "translateY(100%)", // Initially hidden
          animation: "slide-up 0.3s forwards", // Apply slide-up animation
        })}
        onClick={handleOutsideClick}
      >
        {tenant.slug === "miyagi" ? (
          <MiyagiLotteryModal />
        ) : (
          <DefaultLotteryModal />
        )}
      </div>
    </div>
  );
});

const InvitationCard = memo(() => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const hideInvitationCard = localStorage.getItem("hideInvitationCard-v1");
    if (hideInvitationCard) {
      setIsVisible(false);
    } else {
      setIsVisible(true);
    }
  }, []);

  const handleClose = () => {
    localStorage.setItem("hideInvitationCard-v1", "true");
    setIsVisible(false);
  };

  if (!isVisible) {
    return null;
  }

  return (
    <section
      className={css({
        bg: "surface.accentPrimaryLight",
        rounded: "xl",
        p: "16px",
        display: "flex",
        flexDir: "column",
        gap: "16px",
        position: "relative",
      })}
    >
      <button
        type="button"
        className={css({
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "text.primary",
          border: "none",
          cursor: "pointer",
          ml: "auto",
          p: "4px",
          flexShrink: 0,
          position: "absolute",
          top: "8px",
          right: "8px",
        })}
        onClick={handleClose}
      >
        <Close width="24px" height="24px" />
      </button>
      <div
        className={css({
          display: "flex",
          flexDir: "column",
          gap: "10px",
        })}
      >
        <div
          className={css({
            display: "flex",
            gap: "12px",
            pr: "32px",
          })}
        >
          <div className={css({ display: "flex", alignItems: "flex-start" })}>
            <Info
              className={css({
                color: "text.accentPrimary",
                width: "24px",
                height: "24px",
                flexShrink: 0,
              })}
            />
          </div>
          <p>お友だち5名紹介で500ptゲットキャンペーン中！</p>
        </div>
        <p>
          紹介コード、もしくはQRコードを使ってお友だちを5名紹介すると、先着6万名様に500ptをプレゼント！
        </p>
        <div
          className={css({
            display: "flex",
            justifyContent: "right",
          })}
        >
          <Link
            to="/invitation"
            className={css({
              display: "flex",
              gap: "4px",
              bg: "surface.primary",
              rounded: "xl",
              px: "14px",
              py: "8px",
              color: "text.primary",
              fontWeight: 500,
            })}
          >
            <div
              className={css({
                display: "flex",
                alignItems: "center",
              })}
            >
              <Person />
            </div>

            <div className={css({})}>友だち紹介</div>
          </Link>
        </div>
      </div>
    </section>
  );
});

function Home() {
  const {
    totalAmount,
    // nearestExpireAtDate,
    tenant,
    canApplyToPrimaryCampaign,
    showInvitationCard,
  } = Route.useLoaderData();
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    // みやポの場合はモーダルの画像をプリロードする
    if (tenant.slug === "miyagi") {
      const img = new Image();
      img.src = bousaiMiyapo;
    }
  }, [tenant.slug]);

  const CardHeader = () => {
    if (tenant.slug === "miyagi") {
      return (
        <div
          className={css({
            bg: "#355076",
            roundedTop: "xl",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            py: "6px",
          })}
        >
          <img
            src={miyagi}
            alt="logo"
            className={css({
              width: "80px",
              height: "80px",
            })}
          />
        </div>
      );
    }
    if (tenant.slug === "marumori") {
      return (
        <div
          className={css({
            bg: "#FFF5D9",
            roundedTop: "xl",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            py: "6px",
          })}
        >
          <img
            src={marumori}
            alt="logo"
            className={css({
              height: "64px",
            })}
          />
        </div>
      );
    }
    if (tenant.slug === "demo") {
      return (
        <div
          className={css({
            bg: "#fd7f00",
            roundedTop: "xl",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            py: "6px",
          })}
        >
          <img
            src={demo}
            alt="logo"
            className={css({
              height: "64px",
            })}
          />
        </div>
      );
    }
    return null;
  };

  return (
    <>
      <Header title="ホーム" />
      <div
        className={css({
          pt: "24px",
          px: "20px",
          pb: "48px",
          display: "flex",
          flexDir: "column",
          gap: "16px",
        })}
      >
        <Card header={<CardHeader />}>
          <div>
            <div>ポイント残高</div>
            <div
              className={css({
                display: "flex",
                alignItems: "center",
                justifyItems: "center",
              })}
            >
              <div
                className={css({
                  display: "flex",
                  alignItems: "flex-end",
                  gap: "4px",
                  mx: "auto",
                  mt: "4px",
                })}
              >
                <div
                  className={css({
                    fontSize: "40px",
                    fontWeight: 400,
                    color: "text.primary",
                    lineHeight: "100%",
                  })}
                >
                  {formatNumber(totalAmount)}
                </div>
                <div
                  className={css({
                    fontSize: "16px",
                    lineHeight: "150%",
                  })}
                >
                  pt
                </div>
              </div>
            </div>

            {totalAmount > 0 && (
              <div
                className={css({
                  display: "flex",
                  justifyContent: "center",
                })}
              >
                <div
                  className={css({
                    mt: "16px",
                    border: "1px solid",
                    borderColor: "border.secondary",
                    bg: "surface.accentSuccessLight",
                    rounded: "3xl",
                    display: "inline-block",
                    px: "12px",
                    py: "4px",
                    fontSize: "14px",
                    color: "text.secondary",
                  })}
                >
                  令和7年1月6日より利用開始
                </div>
              </div>
            )}

            {/* {nearestExpireAtDate && ( // TODO: リリース直前に有効期限を表示するようにする
              <div
                className={css({ display: "flex", justifyContent: "center" })}
              >
                <div
                  className={css({
                    mt: "16px",
                    border: "1px solid",
                    borderColor: "border.secondary",
                    bg: "surface.accentSuccessLight",
                    rounded: "3xl",
                    display: "inline-block",
                    px: "12px",
                    py: "4px",
                    fontSize: "14px",
                    color: "text.secondary",
                  })}
                >
                  {format(nearestExpireAtDate, "yyyy年MM月dd日 HH:mm")}{" "}
                  まで利用可能
                </div>
              </div>
            )}　*/}

            {tenant.feature.invitation && (
              <Link
                to="/invitation"
                className={css({
                  display: "flex",
                  justifyContent: "center",
                  fontWeight: "500",
                  gap: "4px",
                  mt: "16px",
                })}
              >
                <div
                  className={css({
                    display: "flex",
                    alignItems: "center",
                  })}
                >
                  <Person />
                </div>

                <div
                  className={css({
                    textDecoration: "underline",
                  })}
                >
                  友だち紹介
                </div>
              </Link>
            )}
          </div>
        </Card>

        {canApplyToPrimaryCampaign && (
          <section
            className={css({
              bg: "surface.accentPrimaryLight",
              rounded: "xl",
              p: "16px",
              display: "flex",
              flexDir: "column",
              gap: "16px",
            })}
          >
            <div
              className={css({
                display: "flex",
                flexDir: "column",
                gap: "10px",
              })}
            >
              <div
                className={css({
                  display: "flex",
                  alignItems: "center",
                  gap: "6px",
                })}
              >
                <Info
                  className={css({
                    color: "text.accentPrimary",
                  })}
                />
                <p>ポイント抽選に参加しましょう！</p>
              </div>

              <p
                className={css({
                  color: "text.secondary",
                  fontSize: "14px",
                })}
              >
                {tenant.slug === "miyagi"
                  ? "「みやぎ防災ミニアプリ」に登録して、その場で3,000ポイントが当たるチャンス！"
                  : "即時抽選キャンペーンに参加して、その場で2,000ポイントが当たるチャンス！"}
              </p>
            </div>

            <button
              type="button"
              className={css({
                bg: "surface.accentPrimary",
                rounded: "2xl",
                px: "14px",
                py: "12px",
                color: "white",
                fontWeight: 500,
              })}
              onClick={() => {
                setShowModal(true);
              }}
            >
              抽選に参加
            </button>

            <div
              className={css({
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
              })}
            >
              <Link
                to="/about-campaign"
                className={css({
                  flexGrow: 1,
                  color: "text.accentPrimary",
                  textDecoration: "underline",
                })}
              >
                キャンペーン詳細
              </Link>
            </div>
          </section>
        )}

        {showInvitationCard && <InvitationCard />}
      </div>

      {showModal && (
        <Modal onClickCancel={() => setShowModal(false)} tenant={tenant} />
      )}
    </>
  );
}
