import { css } from "../../styled-system/css";

type Props = {
  header?: React.ReactElement;
  children: React.ReactElement;
};

export function Card({ header, children }: Props) {
  return (
    <section
      className={css({ bg: "white", rounded: "xl", textAlign: "center" })}
    >
      {header}
      <div className={css({ pt: "16px", pb: "20px", px: "24px" })}>
        {children}
      </div>
    </section>
  );
}
