import { createFileRoute } from "@tanstack/react-router";
import { css } from "../../../styled-system/css";

export const Route = createFileRoute("/closed/")({
  component: Closed,
});

function Closed() {
  return (
    <div
      className={css({
        bg: "background.background",
        minH: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      })}
    >
      <section
        className={css({
          bg: "white",
          borderRadius: "16px",
          m: "24px",
        })}
      >
        <div
          className={css({
            p: "24px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            gap: "16px",
          })}
        >
          <h2
            className={css({
              fontSize: "20px",
              fontWeight: "bold",
              color: "text.primary",
            })}
          >
            地域ポイントのご利用ありがとうございました
          </h2>
          <p
            className={css({
              fontSize: "16px",
              color: "text.secondary",
              lineHeight: "1.5",
            })}
          >
            当地域の地域ポイント実証期間は終了しました。
            今後、皆さまのご意見を参考にサービスを改善してまいります。
          </p>
        </div>
      </section>
    </div>
  );
}
