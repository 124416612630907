import { createFileRoute, redirect } from "@tanstack/react-router";
import { z } from "zod";

const searchSchema = z.object({
  c: z.union([z.string(), z.number()]).transform(String),
});

type Search = z.infer<typeof searchSchema>;

export const Route = createFileRoute("/_authed/invitation/apply")({
  validateSearch: (search: Search) => searchSchema.parse(search),
  loaderDeps: ({ search: { c } }: { search: Search }) => ({
    invitationCode: c,
  }),
  loader: async ({ context: { client }, deps: { invitationCode } }) => {
    if (client === undefined) {
      // 権限未取得時のアクセスは client が undefined になるケースがある
      return redirect({
        to: "/",
      });
    }

    // 旧ルーティングのためのリダイレクト
    return redirect({
      to: "/i",
      search: { c: invitationCode },
    });
  },
});
