import { LocalizedMessage } from "@buf/googleapis_googleapis.bufbuild_es/google/rpc/error_details_pb";
import type { PlainMessage } from "@bufbuild/protobuf";
import type { ConnectError } from "@connectrpc/connect";
import {
  getSubscriptionIdWithSignature,
  readResourceWithSignature,
  writeResource,
} from "@pocketsign/in-app-sdk";
import * as jose from "jose";
import type { Tenant } from "schema/gen/es/chiikipoint/model/v2/model_pb";
import { getProfileResourceIds, getSubscriptionIdResourceId } from "../config";
import { createAccountClient } from "./client";
import { AppError, ERROR_CODES } from "./errors";
import { sdk } from "./sdk";
import { getAppEnv } from "./utils";

export function extractConnectErrorMessage(err: ConnectError) {
  const localized = err
    .findDetails(LocalizedMessage)
    .find((i) => i.locale === "ja-JP");

  if (!localized) {
    return err.message;
  }
  return localized.message;
}

export async function login(tenant: TenantWithFeature) {
  const subscriptionIdResourceId = getSubscriptionIdResourceId(tenant.slug);
  const subscriptionIdWithSignature = await getSubscriptionIdWithSignature(sdk);
  const profileResourceIds = getProfileResourceIds(tenant.slug);
  const accountClient = createAccountClient();

  if (subscriptionIdWithSignature.result !== "success") {
    throw new AppError(ERROR_CODES.FAILED_TO_GET_SUBSCRIPTION_ID, undefined, {
      sdkErrorNo: subscriptionIdWithSignature.errno,
    });
  }

  const subscriptionId = jose.decodeJwt<{ subscription_id: string }>(
    subscriptionIdWithSignature.subscriptionId,
  ).subscription_id;

  const subscriptionIdResource = await writeResource(sdk, {
    resourceId: subscriptionIdResourceId,
    resource: { value: JSON.stringify(subscriptionId) },
  });

  if (subscriptionIdResource.result !== "success") {
    const message = (
      subscriptionIdResource.data as {
        hint?: string;
        message?: string;
      }
    ).message;

    throw new AppError(ERROR_CODES.FAILED_TO_WRITE_SUBSCRIPTION_ID, message, {
      sdkErrorNo: subscriptionIdResource.errno,
    });
  }

  const profileResources = await Promise.all(
    profileResourceIds.map(async (resourceId) => ({
      id: resourceId,
      value: await readResourceWithSignature(sdk, { resourceId }),
    })),
  );

  for (const resource of profileResources) {
    if (resource.value.result !== "success") {
      throw new AppError(ERROR_CODES.PROFILE_READ_FAILED, undefined, {
        sdkErrorNo: resource.value.errno,
      });
    }
  }

  const loginResponse = await accountClient
    .login({
      subscriptionId: subscriptionIdWithSignature.subscriptionId,
      profiles: profileResources.map((resource) => {
        // ここでのチェックは不要だが、型エラーを回避するため
        if (resource.value.result !== "success") {
          throw new AppError(ERROR_CODES.PROFILE_READ_FAILED);
        }

        return {
          id: resource.id,
          value: resource.value.value,
        };
      }),
    })
    .catch((e) => {
      throw new AppError(ERROR_CODES.CONNECT_LOGIN_FAILED, e.message, {
        originalError: e,
      });
    });

  return loginResponse;
}

export type TenantWithFeature = PlainMessage<Tenant> & {
  feature: {
    invitation: boolean;
    lottely: boolean;
    storeList: boolean;
    payment: boolean;
  };
};

export async function getTenantWithFeature() {
  const accountClient = createAccountClient();

  const { tenant } = await accountClient.getTenant({}).catch((error) => {
    throw new AppError(ERROR_CODES.CONNECT_GET_TENANT_FAILED, error.message, {
      originalError: error,
    });
  });

  if (!tenant) {
    throw new AppError(ERROR_CODES.TENANT_NOT_FOUND);
  }
  // テナントの機能有無をここで設定する
  const tenantWithFeature: TenantWithFeature = {
    ...tenant,
    feature: {
      invitation: ["miyagi", "demo"].includes(tenant.slug),
      lottely: true,
      storeList: true,
      payment: getAppEnv() !== "prod/live",
    },
  };

  return tenantWithFeature;
}
