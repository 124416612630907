import * as Sentry from "@sentry/react";
import { RouterProvider, createRouter } from "@tanstack/react-router";
import { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import { getTenantSlug } from "./libs/utils";
// Import the generated route tree
import { routeTree } from "./routeTree.gen";

// Create a new router instance
const router = createRouter({
  routeTree,
});

// Register the router instance for type safety
declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }
}

// Render the app
// biome-ignore lint/style/noNonNullAssertion: <explanation>
const rootElement = document.getElementById("root")!;
if (!rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <StrictMode>
      <RouterProvider router={router} />
    </StrictMode>,
  );
}

function getSentryEnv() {
  const slug = getTenantSlug();
  const appEnv = import.meta.env.VITE_APP_ENV;
  return `${slug}-${appEnv}`;
}

if (import.meta.env.VITE_ENABLE_SENTRY === "true") {
  Sentry.init({
    dsn: "https://0755aad840795d7d737bfcf46d319dca@o4504292631707648.ingest.sentry.io/4505941762572288",
    integrations: [Sentry.tanstackRouterBrowserTracingIntegration(router)],

    // パフォーマンスの計測は行わない
    tracesSampleRate: 0,
    // エラーのないセッションでのリプレイは不要なので無効化
    replaysSessionSampleRate: 0,
    // すべてのエラーで replay を有効にする
    replaysOnErrorSampleRate: 1.0,

    environment: getSentryEnv(),
  });
}
