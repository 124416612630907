import { ConnectError } from "@connectrpc/connect";
import { captureException, captureMessage } from "@sentry/react";
import { createFileRoute, redirect } from "@tanstack/react-router";
import { z } from "zod";
import { extractConnectErrorMessage } from "../../../libs/connect";

const searchSchema = z.object({
  c: z.union([z.string(), z.number()]).transform(String),
});

type Search = z.infer<typeof searchSchema>;

export const Route = createFileRoute("/_authed/i/")({
  validateSearch: (search: Search) => searchSchema.parse(search),
  loaderDeps: ({ search: { c } }: { search: Search }) => ({
    invitationCode: c,
  }),
  loader: async ({ context: { client }, deps: { invitationCode } }) => {
    if (client === undefined) {
      // 権限未取得時のアクセスは client が undefined になるケースがある
      return redirect({
        to: "/",
      });
    }

    try {
      await client.createMyInviter({
        invitationCode,
      });

      return redirect({
        to: "/",
        state: {
          toast: {
            message: "招待コードを適用しました",
            type: "success",
          },
        },
      });
    } catch (error) {
      console.error(error);
      // CatchBoundaryに到達しないためここでエラーをキャプチャする

      let skipSentry = false;

      if (error instanceof ConnectError) {
        if (error.rawMessage === "permission_denied") {
          skipSentry = true;
        }
      }

      if (!skipSentry) {
        if (error instanceof ConnectError) {
          captureMessage(extractConnectErrorMessage(error));
        } else {
          captureException(error);
        }
      }

      return redirect({
        to: "/",
        state: {
          toast: {
            message: "招待コードが利用できません",
            type: "error",
          },
        },
      });
    }
  },
});
