import { closeWindow } from "@pocketsign/in-app-sdk";
import { useCallback } from "react";
import ArrowBack from "~icons/material-symbols/arrow-back";
import Close from "~icons/material-symbols/close";
import { css } from "../../styled-system/css";
import { sdk } from "../libs/sdk";

type Props = {
  title: string;
  theme?: "dark";
  onClickBack?: () => void;
};

export default function Header({ title, theme, onClickBack }: Props) {
  const onClickClose = useCallback(() => {
    try {
      closeWindow(sdk);
    } catch (e) {
      console.error(e);
      window.alert(
        "ミニアプリを閉じることができません。アプリケーションを再起動してください。",
      );
    }
  }, []);

  return (
    <header
      className={css({
        bg: theme === "dark" ? "#3D4756" : "background.background",
        h: "48px",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        color: theme === "dark" ? "white" : "text.primary",
      })}
    >
      {onClickBack && (
        <button
          type="button"
          className={css({
            position: "absolute",
            left: 0,
            top: 0,
            height: "48px",
            width: "48px",
            fontSize: "18px",
            pl: "16px",
            textAlign: "center",
          })}
          onClick={onClickBack}
        >
          <ArrowBack className={css({ mx: "auto" })} />
        </button>
      )}
      <h1
        className={css({
          color: "text.primary",
          fontWeight: "500",
          fontSize: "18px",
        })}
      >
        {title}
      </h1>

      <button
        type="button"
        className={css({
          position: "absolute",
          right: 0,
          top: 0,
          height: "48px",
          width: "48px",
          pr: "16px",
          textAlign: "center",
          fontSize: "18px",
        })}
        onClick={onClickClose}
      >
        <Close className={css({ mx: "auto" })} />
      </button>
    </header>
  );
}
