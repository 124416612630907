import { createFileRoute, redirect } from "@tanstack/react-router";
import { CampaignLottery_Result } from "schema/gen/es/chiikipoint/model/v2/model_pb";
import { AppError, ERROR_CODES } from "../../../libs/errors";

export const Route = createFileRoute("/_authed/lottery/apply")({
  loader: async ({ context: { client } }) => {
    const campaign = await client.getPrimaryCampaignFund({}).catch((error) => {
      throw new AppError(
        ERROR_CODES.CONNECT_GET_PRIMARY_CAMPAIGN_FUND_FAILED,
        error.message,
        {
          originalError: error,
        },
      );
    });

    if (!campaign.fundSource) {
      return redirect({
        to: "/",
        state: {
          toast: { message: "キャンペーンが見つかりません", type: "error" },
        },
      });
    }

    const response = await client
      .applyCampaign({
        fundSourceId: campaign.fundSource.id,
      })
      .catch((error) => {
        throw new AppError(
          ERROR_CODES.CONNECT_APPLY_PRIMARY_CAMPAIGN_FAILED,
          error.message,
          {
            originalError: error,
          },
        );
      });

    if (!response.campaignLottery) {
      return redirect({
        to: "/",
        state: {
          toast: { message: "抽選キャンペーンが見つかりません", type: "error" },
        },
      });
    }

    if (
      response.campaignLottery.result === CampaignLottery_Result.UNSPECIFIED
    ) {
      throw new AppError(ERROR_CODES.LOTTERY_RESULT_UNSPECIFIED);
    }

    return redirect({
      to: "/lottery/result",
      search: {
        result: response.campaignLottery.result,
        wonBalance: response.campaignLottery.wonBalance
          ? Number(response.campaignLottery.wonBalance)
          : 0,
      },
    });
  },
});
