import type { TenantWithFeature } from "./connect";
import { getAppEnv } from "./utils";

export function getIsInTargetCity(
  tenant: TenantWithFeature,
  cityAddress: string,
) {
  const appEnv = getAppEnv();

  // prod/live 環境以外では機能を有効にする
  if (appEnv !== "prod/live") {
    return true;
  }

  if (tenant.slug === "marumori") {
    return cityAddress.startsWith("宮城県伊具郡丸森町");
  }

  if (tenant.slug === "miyagi") {
    return cityAddress.startsWith("宮城県");
  }

  return true;
}

// 対象地域外等、利用できないケースで機能を無効にする
export function disableFeatures(tenant: TenantWithFeature): TenantWithFeature {
  return {
    ...tenant,
    feature: {
      invitation: false,
      lottely: false,
      storeList: tenant.feature.storeList,
      payment: false,
    },
  };
}

if (import.meta.vitest) {
  const { describe, it, expect, vi, beforeEach } = import.meta.vitest;

  beforeEach(() => {
    vi.stubEnv("VITE_APP_ENV", "prod-live");
  });

  describe("isInTargetCity", () => {
    it("丸森町のテナントの場合、宮城県伊具郡丸森町の住所のみtrueを返す", async () => {
      const tenant = { slug: "marumori" } as TenantWithFeature;

      expect(getIsInTargetCity(tenant, "宮城県伊具郡丸森町")).toBe(true);
      expect(getIsInTargetCity(tenant, "宮城県仙台市")).toBe(false);
      expect(getIsInTargetCity(tenant, "東京都")).toBe(false);
    });

    it("みやぎのテナントの場合、宮城県内の住所でtrueを返す", async () => {
      const tenant = { slug: "miyagi" } as TenantWithFeature;

      expect(getIsInTargetCity(tenant, "宮城県仙台市")).toBe(true);
      expect(getIsInTargetCity(tenant, "宮城県伊具郡丸森町")).toBe(true);
      expect(getIsInTargetCity(tenant, "東京都")).toBe(false);
    });

    it("その他のテナントの場合、常にtrueを返す", async () => {
      const tenant = { slug: "other" } as TenantWithFeature;

      expect(getIsInTargetCity(tenant, "宮城県仙台市")).toBe(true);
      expect(getIsInTargetCity(tenant, "東京都")).toBe(true);
    });
  });
}
