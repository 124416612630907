// client.ts

import { createPromiseClient } from "@connectrpc/connect";
import { createConnectTransport } from "@connectrpc/connect-web";
import { CushionService } from "schema/gen/es/chiikipoint/cushion/v2/service_connect";
import { AccountService } from "schema/gen/es/chiikipoint/wallet/v2/account_service_connect.js";
import { WalletService } from "schema/gen/es/chiikipoint/wallet/v2/service_connect.js";
import {
  accountServiceMockTransport,
  cushionServiceMockTransport,
  walletServiceMockTransport,
} from "./mock";
import { isMockEnv } from "./utils";

const accountTransport = createConnectTransport({
  baseUrl: "/",
});

const createWalletTransport = ({ token }: { token: string }) =>
  createConnectTransport({
    baseUrl: "/",
    interceptors: [
      (next) => async (req) => {
        req.header.set("Authorization", `Bearer ${token}`);
        return next(req);
      },
    ],
  });

export const createAccountClient = () => {
  const mock = isMockEnv();
  return createPromiseClient(
    AccountService,
    mock ? accountServiceMockTransport : accountTransport,
  );
};

type CreateWalletClientParams = {
  token: string;
  mock?: boolean;
};

export type WalletClient = ReturnType<
  typeof createPromiseClient<typeof WalletService>
>;

export const createWalletClient = ({ token }: CreateWalletClientParams) => {
  const mock = isMockEnv();
  return createPromiseClient(
    WalletService,
    mock ? walletServiceMockTransport : createWalletTransport({ token }),
  );
};

const cushionTransport = createConnectTransport({
  baseUrl: "/",
});

export const createCushionClient = () => {
  const mock = isMockEnv();
  return createPromiseClient(
    CushionService,
    mock ? cushionServiceMockTransport : cushionTransport,
  );
};
