// URL生成スニペット https://www.notion.so/pocketsign/URL-160527616c8e4d428ef5ea0f8e80a877?pvs=4
export function encodeUUID(uuid: string) {
  // https://github.com/uuidjs/uuid/blob/4de23a6030e65ac72b3b015680f08e7e292681ed/src/parse.js#L3C1-L39C2
  function parse(uuid: string) {
    if (
      !(
        typeof uuid === "string" &&
        /^(?:[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}|00000000-0000-0000-0000-000000000000)$/i.test(
          uuid,
        )
      )
    ) {
      throw TypeError("Invalid UUID");
    }
    let v: number;
    const arr = new Uint8Array(16);
    // Parse ########-....-....-....-............
    v = Number.parseInt(uuid.slice(0, 8), 16);
    arr[0] = v >>> 24;
    arr[1] = (v >>> 16) & 0xff;
    arr[2] = (v >>> 8) & 0xff;
    arr[3] = v & 0xff;
    // Parse ........-####-....-....-............
    v = Number.parseInt(uuid.slice(9, 13), 16);
    arr[4] = v >>> 8;
    arr[5] = v & 0xff;
    // Parse ........-....-####-....-............
    v = Number.parseInt(uuid.slice(14, 18), 16);
    arr[6] = v >>> 8;
    arr[7] = v & 0xff;
    // Parse ........-....-....-####-............
    v = Number.parseInt(uuid.slice(19, 23), 16);
    arr[8] = v >>> 8;
    arr[9] = v & 0xff;
    // Parse ........-....-....-....-############
    // (Use "/" to avoid 32-bit truncation when bit-shifting high-order bytes)
    v = Number.parseInt(uuid.slice(24, 36), 16);
    arr[10] = (v / 0x10000000000) & 0xff;
    arr[11] = (v / 0x100000000) & 0xff;
    arr[12] = (v >>> 24) & 0xff;
    arr[13] = (v >>> 16) & 0xff;
    arr[14] = (v >>> 8) & 0xff;
    arr[15] = v & 0xff;
    return arr;
  }
  const toBase64 = (bytes: Uint8Array) => btoa(String.fromCharCode(...bytes));
  // https://github.com/taskcluster/slugid/blob/f6fa9ba5d4008d4cc02e4d360a103d81c6558dbe/slugid.js#L45-L53
  function encode(uuid: string) {
    const bytes = parse(uuid);
    const base64 = toBase64(bytes);
    const slug = base64
      .replace(/\+/g, "-") // Replace + with - (see RFC 4648, sec. 5)
      .replace(/\//g, "_") // Replace / with _ (see RFC 4648, sec. 5)
      .substring(0, 22); // Drop '==' padding
    return slug;
  }
  return encode(uuid);
}

export function p8nDomain() {
  const appEnv = import.meta.env.VITE_APP_ENV;

  if (appEnv === "prod-mock") {
    return "x.p8n.jp";
  }
  if (appEnv === "dev-mock") {
    return "i.p8n.jp";
  }
  if (appEnv === "local") {
    return "l.p8n.jp";
  }

  return "p8n.jp";
}

export function getQRCodeURL(serviceId: string, u?: string) {
  const domain = p8nDomain();

  if (!u) {
    return `https://${domain}/i/${encodeUUID(serviceId)}`;
  }

  return `https://${domain}/i/${encodeUUID(serviceId)}?u=${encodeURIComponent(u)}`;
}

if (import.meta.vitest) {
  const { it, expect, vi } = import.meta.vitest;

  it("encodeUUID", () => {
    expect(encodeUUID("361079aa-3b0e-406d-bc78-c02d65c72e5e")).toBe(
      "NhB5qjsOQG28eMAtZccuXg",
    );
    expect(() => encodeUUID("invalid-string")).toThrowError(TypeError);
  });

  it("getQRCodeURL", () => {
    vi.stubEnv("VITE_APP_ENV", "prod-mock");

    expect(getQRCodeURL("361079aa-3b0e-406d-bc78-c02d65c72e5e")).toBe(
      "https://x.p8n.jp/i/NhB5qjsOQG28eMAtZccuXg",
    );
    expect(
      getQRCodeURL("361079aa-3b0e-406d-bc78-c02d65c72e5e", "/pay/xxx"),
    ).toBe("https://x.p8n.jp/i/NhB5qjsOQG28eMAtZccuXg?u=%2Fpay%2Fxxx");

    vi.stubEnv("VITE_APP_ENV", "dev-mock");
    expect(getQRCodeURL("361079aa-3b0e-406d-bc78-c02d65c72e5e")).toBe(
      "https://i.p8n.jp/i/NhB5qjsOQG28eMAtZccuXg",
    );

    vi.stubEnv("VITE_APP_ENV", undefined);
    expect(getQRCodeURL("361079aa-3b0e-406d-bc78-c02d65c72e5e")).toBe(
      "https://p8n.jp/i/NhB5qjsOQG28eMAtZccuXg",
    );
  });
}
