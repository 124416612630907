// .toLocalString() では非日本語環境で数字の区切りがドットになるケースがあるため、ja-JP を指定する
export function formatNumber(n: number | bigint | undefined): string {
  if (n === undefined) {
    return "";
  }
  return n.toLocaleString("ja-JP");
}

// 基本的にテナント判断は _authed にて getTenant() から取得するが、
// モック環境やSentryなど routes のツリー外で使用する場合はこちらを使用する
export function getTenantSlug() {
  const { hostname } = location;

  const [tenantName, appName] = hostname.split(".");

  if (import.meta.env.VITE_TENANT_SLUG) {
    return import.meta.env.VITE_TENANT_SLUG;
  }

  // モック環境の場合はホスト名が demo-mock のようになるため
  const [tenant] = tenantName.split("-");

  if (appName === "wallet") {
    return tenant;
  }

  return "demo";
}

export function isMockEnv() {
  const backendMode = import.meta.env.VITE_BACKEND_MODE;
  if (backendMode === "MOCK") {
    return true;
  }

  const { hostname } = location;
  const [tenantName] = hostname.split(".");
  return tenantName.endsWith("-mock");
}

export type AppEnv = "dev/mock" | "prod/mock" | "prod/live";

export function getAppEnv(): AppEnv {
  const env = import.meta.env.VITE_APP_ENV;

  if (env === "prod-live") {
    return "prod/live";
  }

  if (env === "local") {
    return "dev/mock";
  }

  if (env === "dev-mock") {
    return "dev/mock";
  }

  if (env === "prod-mock") {
    return "prod/mock";
  }

  throw new Error("env is invalid");
}

export function trimPrefecture(address: string) {
  const prefectures = [
    "北海道",
    "青森県",
    "岩手県",
    "宮城県",
    "秋田県",
    "山形県",
    "福島県",
    "茨城県",
    "栃木県",
    "群馬県",
    "埼玉県",
    "千葉県",
    "東京都",
    "神奈川県",
    "新潟県",
    "富山県",
    "石川県",
    "福井県",
    "山梨県",
    "長野県",
    "岐阜県",
    "静岡県",
    "愛知県",
    "三重県",
    "滋賀県",
    "京都府",
    "大阪府",
    "兵庫県",
    "奈良県",
    "和歌山県",
    "鳥取県",
    "島根県",
    "岡山県",
    "広島県",
    "山口県",
    "徳島県",
    "香川県",
    "愛媛県",
    "高知県",
    "福岡県",
    "佐賀県",
    "長崎県",
    "熊本県",
    "大分県",
    "宮崎県",
    "鹿児島県",
    "沖縄県",
  ];

  for (const prefecture of prefectures) {
    if (address.startsWith(prefecture)) {
      return address.slice(prefecture.length);
    }
  }
  return address; // 都道府県が含まれていない場合そのまま返す
}

if (import.meta.vitest) {
  const { describe, it, expect, vi, beforeEach } = import.meta.vitest;

  describe("getTenantSlug", () => {
    beforeEach(() => {
      vi.stubEnv("VITE_TENANT_SLUG", undefined);
    });

    it("returns the tenant name", () => {
      vi.stubGlobal("location", {
        hostname: "localhost",
      });
      expect(getTenantSlug()).toBe("demo");

      vi.stubGlobal("location", {
        hostname: "127.0.0.1",
      });
      expect(getTenantSlug()).toBe("demo");

      vi.stubGlobal("location", {
        hostname: "demo.wallet.chiikipoint.mock.pocketsign.app",
      });
      expect(getTenantSlug()).toBe("demo");

      vi.stubGlobal("location", {
        hostname: "onagawa.wallet.chiikipoint.mock.pocketsign.app",
      });
      expect(getTenantSlug()).toBe("onagawa");
    });

    it("returns the mock tenant name", () => {
      vi.stubGlobal("location", {
        hostname: "demo-mock.wallet.chiikipoint.mock.pocketsign.app",
      });
      expect(getTenantSlug()).toBe("demo");
    });

    it("returns VITE_TENANT_SLUG when set", () => {
      vi.stubEnv("VITE_TENANT_SLUG", "test-tenant");
      expect(getTenantSlug()).toBe("test-tenant");
    });
  });

  describe("isMockEnv", () => {
    beforeEach(() => {
      vi.stubEnv("VITE_BACKEND_MODE", undefined);
    });

    it("returns true when VITE_BACKEND_MODE is MOCK", () => {
      vi.stubEnv("VITE_BACKEND_MODE", "MOCK");
      expect(isMockEnv()).toBe(true);
    });

    it("returns true when hostname ends with -mock", () => {
      vi.stubGlobal("location", {
        hostname: "demo-mock.wallet.chiikipoint.mock.pocketsign.app",
      });
      expect(isMockEnv()).toBe(true);
    });

    it("returns false when not mock environment", () => {
      vi.stubGlobal("location", {
        hostname: "demo.wallet.chiikipoint.mock.pocketsign.app",
      });
      expect(isMockEnv()).toBe(false);
    });
  });

  describe("getAppEnv", () => {
    beforeEach(() => {
      vi.stubEnv("VITE_APP_ENV", undefined);
    });

    it("prod-live環境の場合、prod/liveを返す", () => {
      vi.stubEnv("VITE_APP_ENV", "prod-live");
      expect(getAppEnv()).toBe("prod/live");
    });

    it("local環境の場合、dev/mockを返す", () => {
      vi.stubEnv("VITE_APP_ENV", "local");
      expect(getAppEnv()).toBe("dev/mock");
    });

    it("dev-mock環境の場合、dev/mockを返す", () => {
      vi.stubEnv("VITE_APP_ENV", "dev-mock");
      expect(getAppEnv()).toBe("dev/mock");
    });

    it("prod-mock環境の場合、prod/mockを返す", () => {
      vi.stubEnv("VITE_APP_ENV", "prod-mock");
      expect(getAppEnv()).toBe("prod/mock");
    });

    it("不正な環境の場合、エラーを投げる", () => {
      vi.stubEnv("VITE_APP_ENV", "invalid-env");
      expect(() => getAppEnv()).toThrow("env is invalid");
    });
  });

  describe("formatNumber", () => {
    it("正しくフォーマットされる", () => {
      expect(formatNumber(undefined)).toBe("");
      expect(formatNumber(0)).toBe("0");
      expect(formatNumber(123)).toBe("123");
      expect(formatNumber(1234)).toBe("1,234");
      expect(formatNumber(BigInt(1234))).toBe("1,234");
    });
  });

  describe("trimPrefecture", () => {
    it("正しく市名を抽出できる", () => {
      expect(trimPrefecture("宮城県仙台市青葉区")).toBe("仙台市青葉区");
      expect(trimPrefecture("北海道仙台市青葉区")).toBe("仙台市青葉区");
      expect(trimPrefecture("東京都仙台市青葉区")).toBe("仙台市青葉区");
      expect(trimPrefecture("京都府京都市上京区")).toBe("京都市上京区");
      expect(trimPrefecture("大阪府大阪市西区")).toBe("大阪市西区");
      expect(trimPrefecture("奈良県奈良市奈良町")).toBe("奈良市奈良町");
      expect(trimPrefecture("奈良県奈良県市奈良町")).toBe("奈良県市奈良町");
      expect(trimPrefecture("那覇市")).toBe("那覇市");
    });
  });
}
