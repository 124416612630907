import { ConnectError } from "@connectrpc/connect";
import { captureException } from "@sentry/react";
// ブラウザバックを使えなくするために、確認から支払い完了まで1ページで完結させる
import { Link, createFileRoute, redirect } from "@tanstack/react-router";
import confetti from "canvas-confetti";
import { format } from "date-fns";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import type {
  Store,
  TransactionGroup,
} from "schema/gen/es/chiikipoint/model/v2/model_pb";
import type { PayResponse } from "schema/gen/es/chiikipoint/wallet/v2/service_pb";
import { useInterval } from "usehooks-ts";
import { z } from "zod";
import CheckCircle from "~icons/material-symbols/check-circle-outline";
import ProgressActivity from "~icons/material-symbols/progress-activity";
import { css } from "../../../../styled-system/css";
import audio from "../../../assets/audio/kessai-kanryou.mp3";
import Header from "../../../components/header";
import WarningBanner from "../../../components/warning-banner";
import { useFooter } from "../../../contexts/footer-context";
import { AppError, ERROR_CODES } from "../../../libs/errors";
import { formatNumber } from "../../../libs/utils";

const paySearchSchema = z.object({
  t: z.string().optional(),
});

type PaySearch = z.infer<typeof paySearchSchema>;

type Mode = "input" | "confirm" | "complete";

export const Route = createFileRoute("/_authed/pay/$storeId")({
  validateSearch: (search) => paySearchSchema.parse(search),
  loader: async ({ context: { client, tenant }, params: { storeId } }) => {
    const [storeResponse, balanceResponse] = await Promise.all([
      client.getStore({ storeId }).catch((error) => {
        throw new AppError(ERROR_CODES.CONNECT_GET_STORE_FAILED, error.message);
      }),
      client.getMyBalances({}).catch((error) => {
        throw new AppError(
          ERROR_CODES.CONNECT_GET_MY_BALANCES_FAILED,
          error.message,
          {
            originalError: error,
          },
        );
      }),
    ]);

    const { store, companyName } = storeResponse;
    const { balances } = balanceResponse;

    const totalAmount = balances.reduce(
      (acc, balance) => acc + Number(balance.amount),
      0,
    );
    if (!store) {
      throw redirect({
        to: "/",
        state: { toast: { message: "店舗が見つかりません", type: "error" } },
      });
    }

    const paymentFeatureDisabled = tenant.feature.payment === false;

    return { store, companyName, totalAmount, client, paymentFeatureDisabled };
  },
  component: Pay,
});

const CurrentTime = memo(() => {
  const [time, setTime] = useState(new Date());

  useInterval(() => {
    setTime(new Date());
  }, 1000);

  return <>{format(time, "yyyy/MM/dd HH:mm:ss")}</>;
});

const StoreBlock = memo(
  ({
    reverse,
    store,
    companyName,
  }: { reverse?: boolean; store: Store; companyName: string }) => {
    return (
      <div
        className={css({
          display: "flex",
          gap: "16px",
        })}
      >
        <img
          src={store?.iconUrl}
          alt={store.name}
          className={css({
            rounded: "full",
            w: "48px",
            h: "48px",
          })}
        />
        <div
          className={css({
            display: "flex",
            flexDirection: "column",
          })}
        >
          <h2
            className={css({
              fontSize: "20px",
              color: reverse ? "white" : "text.primary",
              fontWeight: "600",
            })}
          >
            {store?.name}
          </h2>
          <p
            className={css({
              lineHeight: "1",
            })}
          >
            {companyName}
          </p>
        </div>
      </div>
    );
  },
);

type InputProps = {
  store: Store;
  companyName: string;
  amount: number | undefined;
  onChangeValue: (e: React.ChangeEvent<HTMLInputElement>) => void;
  valid: boolean;
  errorMessage: string | null;
  setMode: React.Dispatch<React.SetStateAction<Mode>>;
  totalAmount: number;
  paymentFeatureDisabled: boolean;
};

const Input = memo(
  ({
    store,
    companyName,
    amount,
    onChangeValue,
    valid,
    errorMessage,
    setMode,
    totalAmount,
    paymentFeatureDisabled,
  }: InputProps) => {
    return (
      <>
        <Header title="支払いポイント入力" />
        {paymentFeatureDisabled && (
          <WarningBanner
            title="支払い機能は利用できません"
            description="利用開始前のため、支払いはできません。"
          />
        )}
        <div
          className={css({
            p: "16px",
            display: "flex",
            flexDirection: "column",
            gap: "16px",
          })}
        >
          <StoreBlock store={store} companyName={companyName} />
          <div
            className={css({
              bg: "white",
              rounded: "xl",
              p: "16px",
            })}
          >
            <div
              className={css({
                color: "text.secondary",
                fontSize: "14px",
              })}
            >
              ポイント残高 {formatNumber(totalAmount)}pt
            </div>
            <input
              value={amount === undefined ? "" : amount}
              onChange={onChangeValue}
              type="tel"
              // biome-ignore lint/a11y/noAutofocus: <explanation>
              autoFocus={true}
              placeholder="0"
              className={css({
                fontSize: "40px",
                color: "text.primary",
                textAlign: "center",
                width: "100%",
                borderBottom: "1px solid",
                borderColor: "border.primary",
              })}
            />
            {errorMessage && (
              <div
                className={css({
                  color: "text.accentError",
                  fontSize: "16px",
                  mt: "2px",
                })}
              >
                {errorMessage}
              </div>
            )}
          </div>

          <button
            type="button"
            onClick={() => setMode("confirm")}
            disabled={!valid || paymentFeatureDisabled}
            className={css({
              bg: "surface.accentPrimary",
              color: "white",
              rounded: "xl",
              px: "32px",
              py: "12px",
              fontSize: "20px",
              fontWeight: "600",
              "&:disabled": {
                bg: "surface.accentDisable",
              },
            })}
          >
            次へ
          </button>
        </div>
      </>
    );
  },
);

type ConfirmProps = {
  store: Store;
  companyName: string;
  amount: number;
  setMode: React.Dispatch<React.SetStateAction<Mode>>;
  setTransactionGroup: React.Dispatch<
    React.SetStateAction<TransactionGroup | null>
  >;
  totalAmount: number;
  pay: () => Promise<PayResponse>;
};

const Confirm = memo(
  ({
    store,
    companyName,
    amount,
    setMode,
    totalAmount,
    pay,
    setTransactionGroup,
  }: ConfirmProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
      setIsOpen(true);
    }, []);

    const handlePay = useCallback(() => {
      setLoading(true);
      pay()
        .then((response) => {
          if (response.transactionGroup) {
            setTransactionGroup(response.transactionGroup);
            setMode("complete");
          } else {
            throw new AppError(ERROR_CODES.CONNECT_PAY_FAILED);
          }
        })
        .catch((error) => {
          if (error instanceof ConnectError) {
            toast.error(
              `支払いに失敗しました \n[${ERROR_CODES.CONNECT_PAY_FAILED}]`,
            );
          } else {
            toast.error(`支払いに失敗しました \n[${error.message}]`);
          }
          captureException(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }, [pay, setMode, setTransactionGroup]);

    return (
      <div
        className={css({
          bg: "#3D4756",
        })}
      >
        <Header title="" theme="dark" onClickBack={() => setMode("input")} />
        <div
          className={css({
            px: "16px",
            pt: "100px",
            display: "flex",
            flexDirection: "column",
            transform: "rotate(180deg)",
            color: "white",
          })}
        >
          <StoreBlock store={store} companyName={companyName} reverse />

          <div
            className={css({
              fontSize: "20px",
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "center",
              gap: 0,
            })}
          >
            <div
              className={css({
                fontSize: "40px",
                textAlign: "center",
                position: "relative",
              })}
            >
              <span>{formatNumber(amount)}</span>

              <div
                className={css({
                  position: "absolute",
                  fontSize: "16px",
                  bottom: "10px",
                  right: "-22px",
                })}
              >
                pt
              </div>
            </div>
          </div>

          <div
            className={css({
              display: "flex",
              justifyContent: "center",
            })}
          >
            <CurrentTime />
          </div>
        </div>

        <div
          className={css({
            p: "16px",
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            bg: "background.background",
            roundedTop: "2xl",
            position: "absolute",
            top: isOpen ? "200px" : 0,
            left: 0,
            right: 0,
            transition: "top 0.8s ease-in-out",
          })}
        >
          <StoreBlock store={store} companyName={companyName} />
          <div
            className={css({
              bg: "white",
              rounded: "xl",
              p: "16px",
            })}
          >
            <div
              className={css({
                color: "text.secondary",
                fontSize: "14px",
              })}
            >
              ポイント残高 {formatNumber(totalAmount)}pt
            </div>
            <div
              className={css({
                fontSize: "20px",
                borderBottom: "1px solid",
                borderColor: "border.primary",
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "center",
                gap: 0,
              })}
            >
              <div
                className={css({
                  fontSize: "40px",
                  color: "text.primary",
                  textAlign: "center",
                  position: "relative",
                })}
              >
                <span>{formatNumber(amount)}</span>

                <div
                  className={css({
                    position: "absolute",
                    color: "text.secondary",
                    fontSize: "16px",
                    bottom: "10px",
                    right: "-22px",
                  })}
                >
                  pt
                </div>
              </div>
            </div>
          </div>

          <button
            type="button"
            onClick={() => handlePay()}
            className={css({
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              bg: "surface.accentPrimary",
              color: "white",
              rounded: "xl",
              px: "32px",
              py: "12px",
              fontSize: "20px",
              fontWeight: "600",
              "&:disabled": {
                bg: "surface.accentDisable",
              },
            })}
            disabled={loading}
          >
            {loading ? (
              <ProgressActivity
                className={css({
                  animation: "spin 1s linear infinite",
                })}
              />
            ) : (
              "支払う"
            )}
          </button>
          <button
            type="button"
            onClick={() => setMode("input")}
            className={css({
              mt: "16px",
            })}
            disabled={loading}
          >
            もどる
          </button>
        </div>
      </div>
    );
  },
);

type CompleteProps = {
  store: Store;
  companyName: string;
  amount: number;
  totalAmount: number;
  setMode: React.Dispatch<React.SetStateAction<Mode>>;
  transactionGroup: TransactionGroup | null;
};

const Complete = memo(
  ({
    store,
    companyName,
    amount,
    totalAmount,
    transactionGroup,
  }: CompleteProps) => {
    const audioRef = useRef<HTMLAudioElement>(null);

    useEffect(() => {
      shoot();
      if (audioRef.current) {
        audioRef.current.play();
      }

      return () => {
        confetti.reset();
      };
    }, []);

    const shoot = useCallback(() => {
      confetti({
        spread: 360,
        ticks: 500,
        gravity: 0.3,
        decay: 0.92,
        startVelocity: 14,
        origin: { y: 0.3 },
        particleCount: 50,
        scalar: 1.6,
        shapes: ["square"],
      });
    }, []);

    return (
      <div
        className={css({
          display: "flex",
          flexDirection: "column",
          minHeight: "100%",
          bg: "white",
        })}
      >
        {/* biome-ignore lint/a11y/useMediaCaption: <explanation> */}
        <audio ref={audioRef} src={audio} />
        <Header title="" theme="dark" />
        <div
          className={css({
            px: "16px",
            pt: "16px",
            display: "flex",
            flexDirection: "column",
            transform: "rotate(180deg)",
            color: "white",
            bg: "#3D4756",
          })}
        >
          <StoreBlock store={store} companyName={companyName} reverse />

          <div
            className={css({
              fontSize: "20px",
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "center",
              gap: 0,
            })}
          >
            <div
              className={css({
                fontSize: "40px",
                textAlign: "center",
                position: "relative",
              })}
            >
              <span>{formatNumber(amount)}</span>

              <div
                className={css({
                  position: "absolute",
                  fontSize: "16px",
                  bottom: "10px",
                  right: "-22px",
                })}
              >
                pt
              </div>
            </div>
          </div>

          <div
            className={css({
              display: "flex",
              justifyContent: "center",
            })}
          >
            <CurrentTime />
          </div>
          <div
            className={css({
              textAlign: "center",
              fontSize: "14px",
            })}
          >
            決済ID: {transactionGroup?.id}
          </div>
        </div>

        <div
          className={css({
            bg: "surface.accentSuccess",
            color: "white",
            display: "flex",
            justifyContent: "center",
            p: "6px",
            fontSize: "14px",
            gap: "6px",
          })}
        >
          <div
            className={css({
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            })}
          >
            <CheckCircle />
          </div>
          <div>支払い完了</div>
        </div>

        <div
          className={css({
            p: "16px",
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            bg: "surface.accentSuccessLight",
            minHeight: "100%",
            flex: 1,
          })}
        >
          <StoreBlock store={store} companyName={companyName} />
          <div
            className={css({
              bg: "white",
              rounded: "xl",
              p: "16px",
            })}
          >
            <div
              className={css({
                color: "text.secondary",
                fontSize: "14px",
              })}
            >
              ポイント残高 {formatNumber(totalAmount)}pt
            </div>
            <div
              className={css({
                fontSize: "20px",
                borderBottom: "1px solid",
                borderColor: "border.primary",
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "center",
                gap: 0,
              })}
            >
              <div
                className={css({
                  fontSize: "40px",
                  color: "text.primary",
                  textAlign: "center",
                  position: "relative",
                })}
              >
                <span>{formatNumber(amount)}</span>

                <div
                  className={css({
                    position: "absolute",
                    color: "text.secondary",
                    fontSize: "16px",
                    bottom: "10px",
                    right: "-22px",
                  })}
                >
                  pt
                </div>
              </div>
            </div>
          </div>

          <Link
            to="/"
            className={css({
              color: "text.accentPrimary",
              textDecoration: "underline",
              textAlign: "center",
            })}
          >
            閉じる
          </Link>
        </div>
      </div>
    );
  },
);

function Pay() {
  const { totalAmount, store, companyName, client, paymentFeatureDisabled } =
    Route.useLoaderData();
  const { t } = Route.useSearch<PaySearch>();
  const [amount, setAmount] = useState<number | undefined>();
  const [valid, setValid] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [mode, setMode] = useState<Mode>("input");
  const { setDisabled: setFooterDisabled } = useFooter();
  const [transactionGroup, setTransactionGroup] =
    useState<TransactionGroup | null>(null);

  // 支払い完了画面までフッターを非表示にする
  useEffect(() => {
    setFooterDisabled(true);

    if (mode === "complete") {
      setFooterDisabled(false);
    }

    return () => {
      setFooterDisabled(false);
    };
  }, [setFooterDisabled, mode]);

  const validate = useCallback(
    (n: number): [boolean, string | null] => {
      if (n <= 0) {
        return [false, "1以上のポイントを入力してください"];
      }

      if (n > totalAmount) {
        return [false, "残高以上のポイントを入力することはできません"];
      }

      return [true, null];
    },
    [totalAmount],
  );

  const onChangeValue = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const inputValue = e.target.value.trim();

      if (inputValue === "") {
        setAmount(undefined);
        setValid(false);
        setErrorMessage(null);
        return;
      }

      const value = Number(inputValue);

      if (Number.isNaN(value)) {
        // 数値以外の入力を許可しない
        // undefined を入れると数値以外が入力されてしまうため 0 を入れる
        setAmount(0);
        return;
      }

      const [isValid, message] = validate(value);

      setValid(isValid);
      setErrorMessage(message);
      setAmount(value);
    },
    [validate],
  );

  const handlePay = useCallback(() => {
    if (amount === undefined) {
      throw new Error("amount is undefined");
    }

    return client.pay({
      storeId: store.id,
      amount: BigInt(amount),
      qrId: t ?? "test",
    });
  }, [amount, store.id, t, client]);

  return (
    <>
      {mode === "input" && (
        <Input
          store={store}
          companyName={companyName}
          amount={amount}
          onChangeValue={onChangeValue}
          valid={valid}
          errorMessage={errorMessage}
          setMode={setMode}
          totalAmount={totalAmount}
          paymentFeatureDisabled={paymentFeatureDisabled}
        />
      )}
      {mode === "confirm" && amount !== undefined && (
        <Confirm
          store={store}
          companyName={companyName}
          amount={amount}
          setMode={setMode}
          totalAmount={totalAmount}
          pay={handlePay}
          setTransactionGroup={setTransactionGroup}
        />
      )}
      {mode === "complete" && amount !== undefined && (
        <Complete
          store={store}
          companyName={companyName}
          amount={amount}
          totalAmount={totalAmount}
          setMode={setMode}
          transactionGroup={transactionGroup}
        />
      )}
    </>
  );
}
