import { createContext, useContext, useState } from "react";

type FooterContextType = {
  disabled: boolean;
  setDisabled: (disabled: boolean) => void;
};

const FooterContext = createContext<FooterContextType | undefined>(undefined);

export function FooterProvider({ children }: { children: React.ReactNode }) {
  const [disabled, setDisabled] = useState(false);

  return (
    <FooterContext.Provider value={{ disabled, setDisabled }}>
      {children}
    </FooterContext.Provider>
  );
}

export function useFooter() {
  const context = useContext(FooterContext);
  if (!context) {
    throw new Error("useFooter must be used within a FooterProvider");
  }
  return context;
}
