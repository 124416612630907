import {
  Outlet,
  createFileRoute,
  useRouterState,
} from "@tanstack/react-router";
import * as jose from "jose";
import { Suspense } from "react";
import { css } from "../../styled-system/css";
import Footer from "../components/footer";
import { PageLoading } from "../components/page-loading";
import { useFooter } from "../contexts/footer-context";
import { type WalletClient, createWalletClient } from "../libs/client";
import {
  type TenantWithFeature,
  getTenantWithFeature,
  login,
} from "../libs/connect";
import {
  getCityAddressWithCache,
  initSDK,
  requestPermissions,
} from "../libs/sdk";
import { disableFeatures, getIsInTargetCity } from "../libs/tenant";

type LoaderData = {
  client: WalletClient;
  tenant: TenantWithFeature;
};

export const Route = createFileRoute("/_authed")({
  beforeLoad: async (): Promise<LoaderData> => {
    const localToken = sessionStorage.getItem("token");

    if (localToken) {
      console.log("beforeLoad: initialize from cache");

      try {
        // tokenをデコードして有効期限を確認
        const decodedToken = jose.decodeJwt(localToken);
        const currentTime = Math.floor(Date.now() / 1000);

        if (!decodedToken.exp || decodedToken.exp < currentTime) {
          throw new Error("Token expired");
        }

        const client = createWalletClient({
          token: localToken,
        });

        const tenant = JSON.parse(
          sessionStorage.getItem("tenant") || "{}",
        ) as TenantWithFeature;

        if (!tenant.slug || !tenant.serviceId) {
          throw new Error("Invalid tenant");
        }

        await initSDK(tenant.serviceId);

        return {
          client,
          tenant,
        };
      } catch (e) {
        // トークンの有効期限切れなどでエラーが発生した場合
        console.error(e);
        sessionStorage.clear();
      }
    }

    // beforLoad のエラーは ErrorBoundry で拾うことができないため、
    // 全体的に try-catch で拾ったエラーを loader でコンポーネントレベルに伝搬させる
    try {
      console.log("beforeLoad: initialize");

      const tenantWithFeature = await getTenantWithFeature();
      await initSDK(tenantWithFeature.serviceId);
      await requestPermissions(tenantWithFeature);
      const loginResponse = await login(tenantWithFeature);
      const client = createWalletClient({
        token: loginResponse.token,
      });
      const isInTargetCity = getIsInTargetCity(
        tenantWithFeature,
        (await getCityAddressWithCache()) ?? "",
      );

      const tenant = isInTargetCity
        ? tenantWithFeature
        : disableFeatures(tenantWithFeature);

      sessionStorage.setItem("token", loginResponse.token);
      sessionStorage.setItem("tenant", JSON.stringify(tenant));

      return {
        client,
        tenant,
      };
    } catch (e) {
      console.error(e);

      // _authed 配下の loader には必ず undefined ではない context を返すため
      // ここでは LoaderData としてエラーを伝搬させる
      return {
        error: e,
      } as unknown as LoaderData;
    }
  },
  loader: ({ context }) => context,
  component: LayoutComponent,
});

function LayoutComponent() {
  const state = useRouterState();
  const data = Route.useLoaderData() as LoaderData & { error?: unknown };
  const { disabled: footerDisabled } = useFooter();

  if ("error" in data) {
    throw data.error;
  }

  return (
    <>
      <main
        className={css({
          flexGrow: 1,
          overflowY: "auto",
          bg: "background.background",
          pt: "48px",
        })}
        style={{
          paddingBottom: footerDisabled
            ? 0
            : "calc(env(safe-area-inset-bottom, 16px) + 70px)",
        }}
      >
        <Suspense fallback={<PageLoading />}>
          {state.isLoading ? <PageLoading /> : <Outlet />}
        </Suspense>
      </main>
      <Footer />
    </>
  );
}
