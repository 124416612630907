import { Link, createFileRoute } from "@tanstack/react-router";
import { CampaignLottery_Result } from "schema/gen/es/chiikipoint/model/v2/model_pb";
import { css } from "../../../../styled-system/css";
import marumoriLost from "../../../assets/marumori/lost.png";
import marumoriWin from "../../../assets/marumori/win.png";
import miyagiLost from "../../../assets/miyagi/lost.png";
import miyagiWin from "../../../assets/miyagi/win.png";
import { Card } from "../../../components/card";
import Header from "../../../components/header";
import { formatNumber } from "../../../libs/utils";

type ResultSearch = {
  result: CampaignLottery_Result.WON | CampaignLottery_Result.LOST;
  wonBalance: number;
};

export const Route = createFileRoute("/_authed/lottery/result")({
  validateSearch: (search: ResultSearch) => {
    return {
      result: search.result,
      wonBalance: search.wonBalance,
    };
  },
  loader: async ({ context: { tenant } }) => {
    return { tenant };
  },
  component: Result,
});

function Result() {
  const { tenant } = Route.useLoaderData();
  const { result, wonBalance } = Route.useSearch();

  const CardHeaderImage = () => {
    if (tenant.slug === "miyagi" && result === CampaignLottery_Result.WON) {
      return (
        <img
          src={miyagiWin}
          alt="大当たり"
          className={css({
            roundedTop: "xl",
          })}
        />
      );
    }

    if (tenant.slug === "miyagi" && result === CampaignLottery_Result.LOST) {
      return (
        <img
          src={miyagiLost}
          alt="はずれ"
          className={css({
            roundedTop: "xl",
          })}
        />
      );
    }

    if (tenant.slug === "marumori" && result === CampaignLottery_Result.WON) {
      return (
        <img
          src={marumoriWin}
          alt="大当たり"
          className={css({
            roundedTop: "xl",
          })}
        />
      );
    }

    if (tenant.slug === "marumori" && result === CampaignLottery_Result.LOST) {
      return (
        <img
          src={marumoriLost}
          alt="はずれ"
          className={css({
            roundedTop: "xl",
          })}
        />
      );
    }

    return null;
  };

  return (
    <>
      <Header title="抽選結果" />
      <div
        className={css({
          py: "24px",
          px: "20px",
          display: "flex",
          flexDir: "column",
          gap: "16px",
        })}
      >
        <Card
          header={
            <div
              className={css({
                roundedTop: "xl",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              })}
            >
              <CardHeaderImage />
            </div>
          }
        >
          <>
            {result === CampaignLottery_Result.WON && (
              <div
                className={css({
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                })}
              >
                <div
                  className={css({
                    fontSize: "32px",
                  })}
                >
                  大当たり！
                </div>
                <div className={css({})}>
                  おめでとうございます！{formatNumber(wonBalance)}
                  ポイントが付与されました！
                </div>
                <div
                  className={css({
                    bg: "surface.accentSuccessLight",
                    rounded: "xl",
                    p: "12px",
                  })}
                >
                  <div
                    className={css({
                      color: "text.secondary",
                    })}
                  >
                    獲得ポイント
                  </div>
                  <div
                    className={css({
                      display: "flex",
                      alignItems: "center",
                      justifyItems: "center",
                      color: "text.accentSuccess",
                    })}
                  >
                    <div
                      className={css({
                        display: "flex",
                        alignItems: "flex-end",
                        gap: "4px",
                        mx: "auto",
                        mt: "4px",
                      })}
                    >
                      <div
                        className={css({
                          fontSize: "40px",
                          fontWeight: 400,
                          lineHeight: "100%",
                        })}
                      >
                        {formatNumber(wonBalance)}
                      </div>
                      <div
                        className={css({
                          fontSize: "16px",
                          lineHeight: "150%",
                          color: "text.secondary",
                        })}
                      >
                        pt
                      </div>
                    </div>
                  </div>
                </div>
                <Link
                  to="/"
                  className={css({
                    color: "text.accentPrimary",
                    textDecoration: "underline",
                  })}
                >
                  ポイント残高
                </Link>
              </div>
            )}
            {result === CampaignLottery_Result.LOST && (
              <div
                className={css({
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                })}
              >
                <div
                  className={css({
                    fontSize: "32px",
                  })}
                >
                  ハズレ...
                </div>
                <div className={css({})}>
                  ざんねんでした。
                  <br />
                  二次抽選にチャレンジしましょう！
                </div>

                <Link
                  to="/about-campaign"
                  className={css({
                    color: "text.accentPrimary",
                    textDecoration: "underline",
                  })}
                >
                  キャンペーン詳細
                </Link>
              </div>
            )}
          </>
        </Card>

        {result === CampaignLottery_Result.LOST && (
          <Link
            to="/"
            className={css({
              color: "text.accentPrimary",
              textDecoration: "underline",
              textAlign: "center",
              mt: "16px",
            })}
          >
            閉じる
          </Link>
        )}
      </div>
    </>
  );
}
